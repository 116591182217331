/**
 * Fetcher Area
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FArea extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/area/', '/Home/Area/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取门店列表（门店列表）
    getStoreList(search, pagination, sorter) {
        const url = this.spellURL('getStoreList', 'store_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取门店列表（筛选项）
    getStoresAsSelect() {
        const url = this.spellURL('getStoreListFilter', 'getStore');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => ({
                id: item.id,
                name: item.name,
            }));
        });
    }

    // 获取门店分类气瓶计数（门店列表）
    getStoreTypeBottle(bottleSet) {
        const url = this.spellURL('getStoreTypeBottle', 'store_bottle_spec_count');
        const send = {
            ...bottleSet,
        };
        return this.post(url, send);
    }

    // 获取门店可编辑信息（门店列表）
    getStoreInfo(store) {
        const url = this.spellURL('getStoreInfo', 'shopInfo');
        const send = {
            ...store,
        };
        return this.post(url, send);
    }

    // 保存门店基本信息（门店列表）
    saveStoreInfo(store) {
        const url = this.spellURL('saveStoreInfo', 'api_store');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(store)),
            storeType: store.storeType,
        };
        delete send['store_type'];
        return this.post(url, send);
    }

    // 删除门店（门店列表）
    deleteStore(store) {
        const url = this.spellURL('saveDeleteStore', 'api_store');
        const send = {
            ...store,
            type: 'del',
        };
        return this.post(url, send);
    }

    // 获取门店详情（门店列表-门店详情）
    getStoreDetail(store) {
        const url = this.spellURL('getStoreDetail', 'shopInfo');
        const send = {
            ...store,
        };
        return this.post(url, send);
    }

    // 生成门店巡查二维码（门店列表-门店详情）
    getStoreNewQRCode(store) {
        const url = this.spellURL('getStoreNewQRCode', '../Shop/generate_qrcode');
        const send = {
            ...store,
        };
        return this.post(url, send);
    }

    // 获取门店在职员工（门店列表-门店详情）
    getStoreEmployees(search, pagination, sorter) {
        const url = this.spellURL('getStoreEmployees', 'store_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            type: 'work-emp',
        };
        return this.post(url, send);
    }

    // 获取门店挂职员工列表（门店列表-门店详情）
    getStoreTemporaries(search, pagination, sorter) {
        const url = this.spellURL('getStoreTemporaries', 'store_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            type: 'ins-emp',
        };
        return this.post(url, send);
    }

    // 删除挂职员工（门店列表-门店详情）
    deleteStoreTemporary(temporary) {
        const url = this.spellURL('deleteStoreTemporary', 'api_pull_employee');
        const send = {
            ...this.transKeyName('underline', temporary),
            type: 'del',
        };
        return this.post(url, send);
    }

    // 获取门店不在职未挂职的配送员列表（门店列表-门店详情）
    getStoreOutDeliverers(store) {
        const url = this.spellURL('getStoreOutDeliverers', 'employee_list');
        const send = {
            ...this.transKeyName('underline', store),
        };
        return this.post(url, send, [['', 'emp', 'rows']]);
    }

    // 保存添加挂职员工（门店列表-门店详情）
    saveStoreAddTemporaries(temporaries) {
        const url = this.spellURL('saveStoreAddTemporaries', 'api_pull_employee');
        const send = {
            ...this.transKeyName('underline', temporaries),
        };
        return this.post(url, send);
    }

    // 获取门店气瓶分类计数（门店列表-门店详情）
    getStoreBottleCounts(search, pagination, sorter) {
        const url = this.spellURL('getStoreBottleCounts', 'store_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            type: 'bottle',
        };
        return this.post(url, send);
    }

    // 获取门店类型气瓶列表（门店列表-门店详情）
    getStoreTypeBottleList(search) {
        const url = this.spellURL('getStoreTypeBottleList', 'store_detail');
        const send = {
            ...this.transKeyName('underline', search),
            type: 'bottle_detail',
        };
        return this.post(url, send, [['', 'bottle', 'rows']]);
    }

    // 获取门店气瓶流转记录（门店列表-门店详情）
    getStoreBottleCirculation(search, pagination, sorter) {
        const url = this.spellURL('getStoreBottleCirculation', 'store_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            type: 'position',
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取门店气瓶流转详情（门店详情）
    getStoreBottleCircleDetail(circle) {
        const url = this.spellURL('getStoreBottleCircleDetail', 'store_gangping');
        const send = {
            ...circle,
        };
        return this.post(url, send);
    }

    // 获取门店状态（门店状态）
    getStoreStatus(search, pagination, sorter) {
        const url = this.spellURL('getStoreStatus', 'shop_status');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取门店销售统计（门店销售统计）
    getStoreSalesStatistic(search, pagination, sorter) {
        const url = this.spellURL('getStoreSalesStatistic', 'Statistic');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取门店销售记录详情（门店销售统计）
    getStoreSalesDetail(store) {
        const url = this.spellURL('getStoreSalesDetail', 'saledetail');
        const send = {
            ...store,
            time: store.time?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取门店地图（门店地图）
    getStoreMap(search) {
        const url = this.spellURL('getStoreMap', 'store_map');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send, [['', 'totalpage', 'totalPage']]);
    }

    // 获取门店在用气瓶计数（门店在用气瓶）
    getStoreUsingBottles(search, pagination, sorter) {
        const url = this.spellURL('getStoreUsingBottles', 'inUseBottle');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                useBottleCnt: 'useBottleCnt',
                depositCnt: 'depositCnt',
            }[sorter.field],
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取门店重瓶计数（门店重瓶库存）
    getStoreHeavyBottleCount(search, pagination, sorter) {
        const url = this.spellURL('getStoreHeavyBottleCount', 'store_inventory');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取门店轻瓶计数（门店轻瓶库存）
    getStoreLightBottleCount(search, pagination, sorter) {
        const url = this.spellURL('getStoreLightBottleCount', 'store_inventory');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取门店气瓶流转信息（门店重瓶库存、门店轻瓶库存）
    getStoreBottleTransit(transit) {
        const url = this.spellURL('getStoreBottleTransit', 'inventory_detail');
        const send = {
            ...this.transKeyName('underline', transit),
        };
        return this.post(url, send);
    }

    // 获取门店收款记录（门店收款报表）
    getStoreCollections(search, pagination, sorter) {
        const url = this.spellURL('getStoreCollections', 'storeCollection');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取门店配送报表
    getStoreDeliveryReport(search, pagination, sorter) {
        const url = this.spellURL('getStoreDeliveryReport', 'shop_delivery');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取首页大屏数据
    getHomeScreen() {
        const url = this.spellURL('getHomeScreen', 'homeScreen');
        return this.post(url);
    }
}

export const $fetchArea = new FArea();
