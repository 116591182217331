/**
 * CImageUploader
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import Zmage from 'react-zmage';
import localResizeIMG from 'lrz';
import { Tools } from '@components/common/Tools';
import { $fetchCommon } from '@fetchers/FCommon';
import './cImageUploader.scss';

export class CImageUploader extends React.Component {
    static propTypes = {
        // 上传图片计数
        count: PropTypes.number,
        // 最大边长
        maxSide: PropTypes.number,
        // 上传图片网址，可以是：具体网址 / 'temporary' / 'formal'
        actionUrl: PropTypes.string,
        // 占位文本
        placeholder: PropTypes.string,
        // 初始值
        value: PropTypes.string,
        // 回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        maxSide: 1600,
    };

    constructor(props) {
        super(props);
        this.state = {
            actionUrl: '',
            fileList: [],
            disabled: false,
            count: this.props.count || 1,
            multiple: this.props.count && this.props.count > 1,
        };
        // 已传文件转换
        if (this.props.value) {
            this.state.fileList = this.props.value.split(',').map((item, index) => {
                const nameMatch = item.match(/[a-zA-Z0-9]+\.[a-zA-Z]+$/);
                return {
                    uid: index,
                    name: nameMatch && nameMatch[0],
                    realUrl: item,
                    thumbUrl: item,
                    status: 'done',
                };
            });
            // 初始张数限制
            if (this.state.fileList.length >= this.state.count) {
                this.state.fileList.length = this.state.count;
                // 多张模式，禁用
                if (this.state.count > 1) {
                    this.state.disabled = true;
                }
            }
        }
        // 上传地址
        if (!this.props.actionUrl || this.props.actionUrl.indexOf('/') < 0) {
            this.state.actionUrl = $fetchCommon.getUploadImageURLSpell(
                this.props.actionUrl,
            );
        } else {
            this.state.actionUrl = this.props.actionUrl;
        }
    }

    _handleBeforeUpload(file) {
        // 非图片终止上传
        if (file.type && file.type.indexOf('image') < 0) {
            return false;
        }
        // 小于 1M 的图片跳过压缩
        if (file.size && file.size <= 1 * 1024 * 1024) {
            return true;
        }
        const option = {
            width: this.props.maxSide,
            height: this.props.maxSide,
        };
        return localResizeIMG(file, option).then(rst => {
            return new Promise((resolve, reject) => {
                if (rst && rst.file) {
                    const fileName = rst.origin.name.replace(/\.[a-zA-Z0-9]+$/, '.jpg');
                    const file = new File([rst.file], fileName, { type: rst.file.type });
                    // 需要给文件对象一个唯一的uid属性，否则将会报错
                    file.uid = rst.origin.uid;
                    resolve(file);
                } else {
                    reject('压缩失败');
                }
            });
        });
    }

    _handleUploadChange(info) {
        // 当事件为上传时，检测是否上传失败
        if (info.file.response) {
            const responseData = info.file.response?.data || {};
            const url =
                responseData.src ||
                responseData.url ||
                responseData.file ||
                responseData.fileName ||
                responseData.file_name;
            // 当无法获取 url，视为上传失败，删除当前上传图片
            if (!url) {
                const fileList = [...info.fileList];
                fileList.splice(-1, 1);
                if (this.state.count === 1) {
                    this.setState({ fileList });
                } else {
                    this.setState({
                        fileList,
                        disabled: fileList.length === this.state.count,
                    });
                }
                message.error(
                    (info.file.response.state
                        ? info.file.response.state.msg
                        : info.file.response.msg) || '图片上传失败！',
                );
                return;
            }
        }
        // 不上传时
        else {
            // 检查文件类型，非图片删除文件
            if (info.file && info.file.type && info.file.type.indexOf('image') < 0) {
                return;
            }
        }
        // 更新
        let fileList = info.fileList.map(file => {
            if (file.response) {
                file.realUrl = file.response.data.src;
            }
            return file;
        });
        // 单图替换
        if (this.state.count === 1) {
            fileList = fileList.slice(-1);
            this.setState({ fileList });
            this.props.onChange(fileList[0] ? fileList[0].realUrl : '');
        }
        // 多图上传限制个数
        else {
            if (fileList.length <= this.state.count) {
                this.setState({
                    fileList,
                    disabled: fileList.length === this.state.count,
                });
                this.props.onChange(
                    fileList
                        .map(file => file.realUrl)
                        .filter(Boolean)
                        .join(','),
                );
            }
        }
    }

    _handlePreview(file) {
        if (!file.preview && file.originFileObj) {
            Tools.getFileBase64(file.originFileObj).then(base64 => {
                file.preview = base64;
                this._handlePreview(file);
            });
            return;
        }
        Zmage.browsing({
            backdrop: 'rgba(0,0,0,0.45)',
            edge: 20,
            src: file.preview || file.thumbUrl,
        });
    }

    _handleRemove(file) {
        if (!$fetchCommon.saveRemoveImage) {
            return;
        }
        let removeUrl = file.realUrl;
        if (removeUrl.indexOf('upload') >= 0) {
            removeUrl = $fetchCommon.transImgPath('cut', removeUrl);
        }
        $fetchCommon.saveRemoveImage({ fileName: removeUrl }).then(res => {});
    }

    _renderHelp() {
        if (this.state.multiple) {
            const surplus = this.state.count - this.state.fileList.length;
            if (surplus > 0) {
                return (
                    <span className="holder">
                        可一次选择多张上传，还可上传 {surplus} 张
                    </span>
                );
            } else {
                return <span className="holder">更换图片请先删除，然后再上传</span>;
            }
        } else {
            return (
                <span className="holder">
                    {this.state.fileList.length === 0 ? '' : '再次上传可替换'}
                </span>
            );
        }
    }

    render() {
        return (
            <Upload
                multiple={this.state.multiple}
                accept="image/gif,image/png,image/jpeg,image/jpg"
                className={[
                    'c-image-uploader',
                    this.state.multiple ? 'multiple' : '',
                ].join(' ')}
                listType="picture"
                fileList={this.state.fileList}
                action={this.state.actionUrl}
                headers={{ 'Ax-Rq-Type': 'separation' }}
                onPreview={evt => this._handlePreview(evt)}
                beforeUpload={evt => this._handleBeforeUpload(evt)}
                onChange={evt => this._handleUploadChange(evt)}
                onRemove={evt => this._handleRemove(evt)}
            >
                <Button type="primary" disabled={this.state.disabled}>
                    <CloudUploadOutlined />
                    {this.props.placeholder}
                </Button>
                {this._renderHelp()}
            </Upload>
        );
    }
}
