/**
 * CToolDetailExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CToolMergeExport } from './CToolMergeExport';
import { CIconDetailExport } from '@components/fragments/icons/CIconDetailExport';

export class CToolDetailExport extends React.Component {
    static propTypes = {
        // 导出文件名
        fileName: PropTypes.string,
        // 列设置
        columns: PropTypes.array,
        // 当前页数
        total: PropTypes.number,
        // 操作权限
        permission: PropTypes.bool,
        // 时间限制开启
        timeLimited: PropTypes.bool,
        // 导出回调
        onExport: PropTypes.func,
    };

    static defaultProps = {
        permission: true,
        timeLimited: false,
    };

    render() {
        return (
            <div>
                <CToolMergeExport
                    tipTitle="导出详细数据"
                    fileName={this.props.fileName}
                    columns={this.props.columns}
                    total={this.props.total}
                    icon={<CIconDetailExport className="c-table-merge-export" />}
                    permission={this.props.permission}
                    timeLimited={this.props.timeLimited}
                    onExport={evt => this.props.onExport(evt)}
                />
            </div>
        );
    }
}
