/**
 * CInputNumStep
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import './cInputNumStep.scss';

export class CInputNumStep extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        width: PropTypes.number,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        width: 120,
        value: 0,
        disabled: false,
        onChange: () => null,
    };

    // 增减
    _handleStep(type) {
        let val2 = this.props.value;
        if (type === 'left') {
            val2 -= val2 === 0 ? 0 : 1;
        } else if (type === 'right') {
            val2++;
        }
        this.props.onChange(val2);
    }

    // 输入
    _handleValueChange(val) {
        const val2 = parseInt(val);
        this.props.onChange(val2);
    }

    render() {
        return (
            <div className="c-input-num-step" style={{ width: this.props.width }}>
                <Button
                    className="step-left"
                    disabled={this.props.disabled}
                    onClick={evt => this._handleStep('left')}
                >
                    <MinusOutlined />
                </Button>
                <Input
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onChange={evt => this._handleValueChange(evt.target.value)}
                />
                <Button
                    className="step-right"
                    disabled={this.props.disabled}
                    onClick={evt => this._handleStep('right')}
                >
                    <PlusOutlined />
                </Button>
            </div>
        );
    }
}
