/**
 * CGroupSelect
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export class CGroupSelect extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 行内样式
        style: PropTypes.object,
        // 输入框清空按钮
        allowClear: PropTypes.bool,
        // 是否禁用
        disabled: PropTypes.bool,
        // 占位提示
        placeholder: PropTypes.string,
        // 初始项值
        value: PropTypes.any,
        // 改变项值后的回调
        onChange: PropTypes.func,
        // 下拉框选项列队
        options: PropTypes.array.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    _renderItemText(item) {
        if (item.explain) {
            return (
                <>
                    {item.name}
                    <span style={{ marginLeft: 20, color: '#888' }}>
                        ( {item.explain} )
                    </span>
                </>
            );
        } else {
            return item.name;
        }
    }

    _renderItem(item) {
        let value;
        let disabled = false;
        if (typeof item.value !== 'undefined') {
            value = item.value;
        } else if (typeof item.id !== 'undefined') {
            value = item.id;
        }
        if (typeof item.disabled !== 'undefined') {
            disabled = item.disabled;
        }
        return (
            <Select.Option value={value} key={value} disabled={disabled}>
                {this._renderItemText(item)}
            </Select.Option>
        );
    }

    _renderGroup(group) {
        let value;
        if (typeof group.value !== 'undefined') {
            value = group.value;
        } else if (typeof group.id !== 'undefined') {
            value = group.id;
        }
        return (
            <Select.OptGroup label={group.name} key={value}>
                {group.children.map(item => {
                    return this._renderItem(item);
                })}
            </Select.OptGroup>
        );
    }

    render() {
        return (
            <span className={['c-plain-select', this.props.className].join(' ')}>
                <Select
                    showSearch
                    optionFilterProp="children"
                    value={this.props.value}
                    allowClear={this.props.allowClear}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    onChange={evt => this.props.onChange(evt)}
                >
                    {this.props.options
                        ? this.props.options.map(group => this._renderGroup(group))
                        : null}
                </Select>
            </span>
        );
    }
}
