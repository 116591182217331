/**
 * CToolCurExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { BXlsxExport } from '@components/plugins/richTable/bases/BXlsxExport';
import { Button, Tooltip, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './cToolCurExport.scss';

export class CToolCurExport extends BXlsxExport {
    static propTypes = {
        // 列设置
        columns: PropTypes.array,
        // 扩展列
        extraColumns: PropTypes.array,
        // 导出文件名
        fileName: PropTypes.string,
        // 当前页码
        pageIndex: PropTypes.number,
        // 当前页数据
        dataSource: PropTypes.array,
        // 操作权限
        permission: PropTypes.bool,
    };

    static defaultProps = {
        fileName: '表格',
        pageIndex: 1,
        columns: [],
        extraColumns: [],
        permission: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            // 工作中状态
            exportLoading: true,
        };
        this._data = {
            // 结束渲染CD
            endTimer: 0,
        };
        this.$refs = {};
        this._export.mode = 'cur';
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _hanldeStart() {
        this.setState({ exportLoading: true });
    }

    _hanldeEnd() {
        clearTimeout(this._data.endTimer);
        this._data.endTimer = setTimeout(() => {
            this.setState({ exportLoading: false });
        }, 1000);
    }

    _hanldeExport() {
        if (!this.props.permission) {
            message.error('您没有导出此数据的权限！');
            return;
        }
        this.setState({ exportLoading: true });
        this.graspRowDatasFromTable();
        this.onDownloadXLSX();
        setTimeout(() => {
            this.clearRowDatas();
        }, 100);
    }

    render() {
        return (
            <div
                className="c-cur-export"
                onMouseEnter={evt => this._hanldeStart()}
                onMouseLeave={evt => this._hanldeEnd()}
            >
                <Tooltip title="导出当前页面" mouseEnterDelay={0}>
                    <Button size="small" onClick={evt => this._hanldeExport()}>
                        <UploadOutlined className="c-table-cur-export" />
                    </Button>
                </Tooltip>
                {this.renderExportTable(this.state.exportLoading)}
            </div>
        );
    }
}
