/**
 * CQRCode
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import {} from 'antd';
import QRCode from 'qrcode';
import './cQRCode.scss';

export class CQRCode extends React.Component {
    static propTypes = {
        // 二维码内容
        content: PropTypes.string,
        // 二维码尺寸
        size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        // 二维码留边
        margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    static defaultProps = {
        size: 200,
        margin: 2,
    };

    constructor() {
        super();
        this.state = {};
        this._data = {
            content: '',
        };
        this.$refs = {};
    }

    componentDidMount() {
        this._handleQRCode();
    }

    _handleQRCode() {
        if (!this.$refs.canvas) {
            return;
        }
        const canvasDom = this.$refs.canvas;
        QRCode.toCanvas(
            canvasDom,
            this.props.content || 'http://www.aisim.cn',
            {
                width: this.props.size,
                margin: this.props.margin,
            },
            err => {
                if (err) {
                    console.error(err);
                    return;
                }
                this._data.content = this.props.content;
            },
        );
        setTimeout(() => {
            this._handleImg();
        }, 10);
    }

    _handleImg() {
        if (!this.props.content) {
            return;
        }
        if (!this.$refs.img) {
            return;
        }
        this.$refs.img.src = this.$refs.canvas.toDataURL('image/png');
    }

    render() {
        if (this._data.content !== this.props.content) {
            this._handleQRCode();
        }
        return (
            <div className="c-qr-code">
                <canvas ref={elm => (this.$refs.canvas = elm)}></canvas>
                <img
                    style={{ display: this.props.content ? '' : 'none' }}
                    ref={elm => (this.$refs.img = elm)}
                    width={this.props.size}
                    height={this.props.size}
                    alt=""
                />
            </div>
        );
    }
}
