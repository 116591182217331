/**
 * CContentBox
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import './cContentBox.scss';

export class CContentBox extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {};

    render() {
        const { className, children, ...resetProps } = this.props;
        return (
            <div className={['c-content-box', className].join(' ')} {...resetProps}>
                {children}
            </div>
        );
    }
}
