/**
 * CNotice
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import {} from '@ant-design/icons';
import './cNotice.scss';

export class CNotice extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 样式
        style: PropTypes.object,
        // Tag 文字
        title: PropTypes.string,
        // Tag 颜色
        color: PropTypes.string,
        // 单行内容
        lineContent: PropTypes.node,
        // 多行内容
        rowsContent: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        title: '注意',
        color: 'red',
    };

    _renderLineContent() {
        if (!this.props.lineContent) {
            return null;
        }
        return <span>：{this.props.lineContent}</span>;
    }

    _renderRowChildren(row) {
        if (!row.children || row.children.length === 0) {
            return null;
        }
        let head = null;
        if (row.subhead) {
            head = <strong className="c-notice-subhead">{row.subhead}</strong>;
        }
        return (
            <>
                {head}
                {row.children.map((item, index) => (
                    <p key={index}>
                        <big>*</big>
                        <span className="c-notice-chilren">{item}</span>
                    </p>
                ))}
            </>
        );
    }

    _renderRowsContent() {
        if (!this.props.rowsContent || this.props.rowsContent.length === 0) {
            return null;
        }
        return (
            <ul>
                {this.props.rowsContent.map((row, index) => (
                    <li key={index}>
                        {row.content}
                        {this._renderRowChildren(row)}
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        return (
            <div
                className={['c-notice', this.props.className].join(' ')}
                style={this.props.style}
            >
                <Tag color={this.props.color}>{this.props.title}</Tag>
                {this._renderLineContent()}
                {this._renderRowsContent()}
            </div>
        );
    }
}
