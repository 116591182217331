/**
 * CExpandableEllipsis
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { Tools } from '@components/common/Tools';

export class CExpandableEllipsis extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            expandable: false,
            opened: false,
            openOffset: { top: 0, left: 0 },
            openWidth: 0,
        };
        this.$refs = {};
        this._listeners = {};
        this._popupContainer = null;
    }

    componentDidMount() {
        this._listeners.onExpand = () => {
            if (this.$refs.td.scrollWidth >= this.$refs.td.offsetWidth) {
                this.setState({ expandable: true });
            } else {
                this.setState({ expandable: false });
            }
        };
        this._listeners.onLeave = () => {
            this.setState({ expandable: false });
        };
        this.$refs.td.addEventListener('mouseenter', this._listeners.onExpand);
        this.$refs.td.addEventListener('touchstart', this._listeners.onExpand);
        this.$refs.td.addEventListener('mouseleave', this._listeners.onLeave);
        this.$refs.td.addEventListener('blur', this._listeners.onLeave);
        // 悬浮层
        this._popupContainer = document.createElement('div');
    }

    componentWillUnmount() {
        this.$refs.td.removeEventListener('mouseenter', this._listeners.onExpand);
        this.$refs.td.removeEventListener('touchstart', this._listeners.onExpand);
        this.$refs.td.removeEventListener('mouseleave', this._listeners.onLeave);
        this.$refs.td.removeEventListener('blur', this._listeners.onLeave);
        // 悬浮层
        ReactDOM.unmountComponentAtNode(this._popupContainer);
        this._popupContainer = null;
    }

    _getElementParentRicTable() {
        const fromElm = this.$refs.td;
        let parent = fromElm.parentElement;
        while (parent.className.indexOf('c-rich-table') < 0) {
            if (!parent.parentElement) {
                parent = null;
                break;
            }
            parent = parent.parentElement;
        }
        return parent;
    }

    // 打开关闭悬浮层
    _switchFloating(type) {
        let open;
        if (typeof type === 'undefined') {
            open = !this.state.opened;
        } else {
            // 指定状态与当前相同，不操作
            if (!!type === this.state.opened) {
                return null;
            } else {
                open = !!type;
            }
        }
        // 打开
        if (open) {
            const maxWidth = Math.min(500, parseInt(window.innerWidth / 2));
            const thRect = this.$refs.td.getBoundingClientRect();
            const thScrollW = this.$refs.td.scrollWidth;
            const floatingWidth =
                thScrollW + 24 > maxWidth ? maxWidth : thScrollW + 8 + 1;
            let offsetLeft = 0;
            // 右侧位置不够时，改右对齐
            if (thRect.left + thScrollW + 30 > window.innerWidth) {
                offsetLeft = thRect.left + this.$refs.td.clientWidth - floatingWidth;
            }
            // 左对齐
            else {
                offsetLeft = thRect.left;
            }
            offsetLeft = Tools.limitNumberRange(offsetLeft, [
                0,
                window.innerWidth - floatingWidth,
            ]);
            // 父级
            const parentElm = this._getElementParentRicTable();
            parentElm && parentElm.appendChild(this._popupContainer);
            const parentRect = parentElm.getBoundingClientRect();
            setTimeout(() => {
                this.setState({
                    opened: true,
                    openOffset: {
                        top: thRect.top - parentRect.top,
                        left: offsetLeft - parentRect.left,
                    },
                    openWidth: floatingWidth,
                });
                ReactDOM.render(this._renderFloating(), this._popupContainer);
            }, 0);
            // 关闭上一个悬浮层
            if (CExpandableEllipsis.lastOpened) {
                if (CExpandableEllipsis.lastOpened !== this) {
                    CExpandableEllipsis.lastOpened.$closeOpened();
                }
            }
            CExpandableEllipsis.lastOpened = this;
        }
        // 关闭
        else {
            this.setState({
                opened: false,
                openOffset: { top: 0, left: 0 },
                openWidth: 0,
            });
            ReactDOM.render(this._renderFloating(), this._popupContainer);
            setTimeout(() => {
                if (this._popupContainer.parentElement) {
                    this._popupContainer.parentElement.removeChild(this._popupContainer);
                }
            }, 10);
        }
    }

    _renderFloating() {
        if (this.state.opened) {
            return (
                <div
                    className="c-rich-table-ellipsis-floating"
                    style={{ ...this.state.openOffset, width: this.state.openWidth }}
                >
                    <CloseOutlined onClick={evt => this._switchFloating()} />
                    <div className="inner">{this.props.children}</div>
                </div>
            );
        } else {
            return null;
        }
    }

    // 展开项
    _renderExpandIcon() {
        if (this.state.expandable) {
            return (
                <span className="trigger" onClick={evt => this._switchFloating()}>
                    <DownOutlined />
                </span>
            );
        } else {
            return null;
        }
    }

    render() {
        const { className, children, ...restProps } = this.props;
        return (
            <td
                {...restProps}
                className={[
                    className,
                    'c-expand-ellipsis',
                    this.state.opened ? 'opened' : '',
                ].join(' ')}
                ref={elm => (this.$refs.td = elm)}
            >
                {children}
                {this._renderExpandIcon()}
            </td>
        );
    }

    // 关闭悬浮层
    $closeOpened() {
        this._switchFloating(false);
    }

    static lastOpened = null;
}
