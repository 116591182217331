/**
 * Fetcher Money
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FMoney extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/money/', '/Home/Money/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取红包列表（红包列表）
    getRedPacketList(search, pagination, sorter) {
        const url = this.spellURL('getRedPacketList', '../Hongbao/hongbao_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取抢红包会员列表（红包列表）
    getRedPacketUsers(packet) {
        const url = this.spellURL('getRedPacketUsers', '../Hongbao/roll_info');
        const send = {
            ...packet,
        };
        return this.post(url, send);
    }

    // 获取充值记录（充值记录）
    getRechargeList(search, pagination, sorter) {
        const url = this.spellURL('getRechargeList', 'chongzhi');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取充值策略（充值记录）
    getRechargeStrategy() {
        const url = this.spellURL('getRechargeStrategy', '../Setting/chongzhi_celue_list');
        const send = {};
        return this.post(url, send);
    }

    // 保存充值策略（充值记录）
    saveRechargeStrategy(strategy) {
        const url = this.spellURL('saveRechargeStrategy', '../Setting/api_chongzhi_celue');
        const send = {
            ...strategy,
        };
        return this.post(url, send);
    }

    getRechargeDetail() {
        // TODO
        return Promise.resolve(null);
    }

    // 获取优惠券派券记录（派券记录）
    getCouponDistributions(search, pagination, sorter) {
        const url = this.spellURL('getCouponDistributions', 'faquan_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取优惠券派券详情（派券记录）
    getCouponDistributionDetail(coupon) {
        const url = this.spellURL('getCouponDistributionDetail', 'faquan_detail');
        const send = {
            ...coupon,
        };
        return this.post(url, send);
    }

    // 获取优惠规则（优惠规则）
    getCouponRules(search, pagination, sorter) {
        const url = this.spellURL('getCouponRules', '../Preferential/lists');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send, [['rows.[]', 'preferentialRuleId', 'id']]);
    }

    // 获取优惠规则（优惠规则）------配送设置---残气兑换
    getResidualExchangeBindRule(target) {
        const url = this.spellURL('getResidualExchangeBindRule', '../Preferential/lists');
        return this.post(url, target);
    }

    // 获取优惠规则类型（筛选项）
    getCouponRuleTypeAsSelect() {
        const url = this.spellURL('getCouponRuleTypeFilter', '../Preferential/preferential_type');
        const send = {};
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.type || []).map(item => ({
                id: item.id,
                name: item.name,
            }));
        });
    }

    // 获取优惠规则列表（筛选项）
    getCouponRulesAsSelect(search) {
        const url = this.spellURL(
            'getCouponRulesFilter',
            '../Preferential/getPreferentialrulesField',
        );
        const send = {
            ...search,
        };
        return this.post(url, send).then(res => {
            if (!res) {
                return null;
            }
            return (res.rows || []).map(item => ({
                id: item.preferentialRuleId,
                name: item.ruleName,
            }));
        });
    }

    // 获取优惠规则信息（优惠规则）
    getCouponRuleInfo(rule) {
        const url = this.spellURL('getCouponRuleInfo', '../Preferential/preferential_info');
        const send = {
            ...rule,
        };
        return this.post(url, send, [['rule', 'preferentialRuleId', 'id']]);
    }

    // 保存优惠规则信息（优惠规则）
    saveCouponRuleInfo(rule) {
        const url = this.spellURL('saveCouponRuleInfo', '../Preferential/api_preferential');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(rule)),
        };
        return this.post(url, send);
    }

    // 保存优惠规则上下线状态（优惠规则）
    saveCouponRulesOnline(rule) {
        const url = this.spellURL('saveCouponRulesOnline', '../Preferential/api_preferential');
        const send = {
            ...this.transKeyName('underline', rule),
        };
        return this.post(url, send);
    }

    // 保存优惠规则指定区域开启状态（优惠规则）
    saveCouponRulesAreaValve(rule) {
        const url = this.spellURL('saveCouponRulesAreaValve', '../Preferential/api_preferential');
        const send = {
            ...this.transKeyName('underline', rule),
        };
        return this.post(url, send);
    }

    // 获取优惠规则关联的商品（优惠规则）
    getCouponRuleCorrelatedProduct(rule) {
        const url = this.spellURL('getCouponRuleCorrelatedProduct', '../Preferential/link_product');
        const send = {
            ...this.transKeyName('underline', rule),
        };
        return this.post(url, send, [['rows.[]', 'preferentialProductLinkId', 'linkId']]);
    }

    // 删除优惠规则关联商品（优惠规则）
    saveDeleteCouponRuleCorrelation(correlation) {
        const url = this.spellURL(
            'saveDeleteCouponRuleCorrelation',
            '../Preferential/api_link_product',
        );
        const send = {
            ...this.transKeyName('underline', correlation),
        };
        return this.post(url, send);
    }

    // 获取优惠规则可关联商品（优惠规则）
    getCouponRuleCorrelateAdditives(rule) {
        const url = this.spellURL(
            'getCouponRuleCorrelateAdditives',
            '../Preferential/api_link_product',
        );
        const send = {
            ...this.transKeyName('underline', rule),
        };
        return this.post(url, send);
    }

    // 保存优惠规则商品关联（优惠规则）
    saveCouponRuleCorrelation(correlation) {
        const url = this.spellURL('saveCouponRuleCorrelation', '../Preferential/api_link_product');
        const send = {
            ...this.transKeyName('underline', correlation),
        };
        return this.post(url, send);
    }

    // 获取优惠规则使用区域（优惠规则）
    getCouponRuleUseArea(rule) {
        const url = this.spellURL('getCouponRuleUseArea', '../Preferential/use_area_list');
        const send = {
            ...this.transKeyName('underline', rule),
        };
        return this.post(url, send, [['rows.[]', 'preferentialRuleId', 'ruleId']]);
    }

    // 保存优惠规则使用区域状态（优惠规则）
    saveCouponRuleUseAreaOnline(rule) {
        const url = this.spellURL('saveCouponRuleUseAreaOnline', '../Preferential/api_use_area');
        const send = {
            ...this.transKeyName('underline', rule),
        };
        return this.post(url, send);
    }

    // 保存优惠规则使用区域（优惠规则）
    saveCouponRuleUseArea(area) {
        const url = this.spellURL('saveCouponRuleUseArea', '../Preferential/api_use_area');
        const send = {
            ...this.transKeyName('underline', area),
        };
        return this.post(url, send);
    }

    // 获取优惠券使用情况（今日优惠券）
    getCouponsUsedCounts(search, pagination, sorter) {
        const url = this.spellURL('getCouponsUsedCounts', '../Preferential/preferential_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send, [['rows.[]', 'totalMonery', 'totalMoney']]);
    }
}

export const $fetchMoney = new FMoney();
