/**
 * ChinaLocatins Loader
 * @author Tevin
 */

export class ChinaLocations {
    constructor() {
        this._data = {
            locationOptionLv3: [],
            locationOptionLv4: [],
        };
        this._loadResource();
    }

    _loadResource() {
        if (!window.top.ChinaLocatinRes) {
            fetch('/static/assets/datas/ChinaLocations.lv4.min.json')
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return {}
                    }
                })
                .then(res => {
                    window.top.ChinaLocatinRes = res;
                })
        }
    }

    getLocationRes(callback) {
        if (window.top.ChinaLocatinRes) {
            callback(window.top.ChinaLocatinRes);
        } else {
            setTimeout(() => {
                this.getLocationRes(callback);
            }, 200);
        }
    }

    _createLocationDatas(level, callback) {
        this.getLocationRes(locationRes => {
            const treeDatas = [];
            // lv1-省
            Object.keys(locationRes).forEach(provinceCode => {
                const province = {
                    label: locationRes[provinceCode].name,
                    value: provinceCode,
                    children: [],
                };
                // lv2-市
                const cityContainer = locationRes[provinceCode].children;
                Object.keys(cityContainer).forEach(cityCode => {
                    const city = {
                        value: cityCode,
                        children: [],
                    };
                    if (typeof cityContainer[cityCode] === 'string') {
                        city.label = cityContainer[cityCode];
                    } else {
                        city.label = cityContainer[cityCode].name;
                        // lv3-区
                        const distContainer = cityContainer[cityCode].children || {};
                        Object.keys(distContainer).forEach(distCode => {
                            const dist = {
                                value: distCode,
                            };
                            if (typeof distContainer[distCode] === 'string') {
                                dist.label = distContainer[distCode];
                            } else {
                                dist.label = distContainer[distCode].name;
                                // lv4-街
                                if (level === '4') {
                                    dist.children = [];
                                    const streetContainer = distContainer[distCode].children || {};
                                    Object.keys(streetContainer).forEach(streetCode => {
                                        const street = {
                                            label: streetContainer[streetCode],
                                            value: streetCode,
                                        };
                                        dist.children.push(street);
                                    });
                                }
                            }
                            city.children.push(dist);
                        });
                    }
                    province.children.push(city);
                });
                treeDatas.push(province);
            });
            callback(treeDatas);
        });
    }

    // 获取地址联动选项
    getLocationDatas(level, callback) {
        if (level === '3') {
            if (this._data.locationOptionLv3.length > 0) {
                callback(this._data.locationOptionLv3);
            } else {
                this._createLocationDatas(level, datas => {
                    this._data.locationOptionLv3 = datas;
                    callback(datas);
                });
            }
        } else if (level === '4') {
            if (this._data.locationOptionLv4.length > 0) {
                callback(this._data.locationOptionLv4);
            } else {
                this._createLocationDatas(level, datas => {
                    this._data.locationOptionLv4 = datas;
                    callback(datas);
                });
            }
        }
    }

    // 获取省市区拼合文本
    getRegionText(regions, callback) {
        this.getRegionNames(regions, address => {
            callback(address.join(''));
        });
    }

    // 省市区名称
    getRegionNames(regions, callback) {
        if (typeof regions === 'string') {
            regions = regions.split(',');
        }
        if (!regions || regions.length === 0 || !regions[0]) {
            callback([]);
        }
        this.getLocationRes(locations => {
            const address = [];
            let tempLocationData = locations;
            regions.forEach(code => {
                if (!tempLocationData[code]) {
                    return;
                }
                if (typeof tempLocationData[code].name === 'string') {
                    address.push(tempLocationData[code].name);
                } else {
                    address.push(tempLocationData[code]);
                }
                tempLocationData = tempLocationData[code].children;
            });
            callback(address);
        });
    }

    // 省市区文本转code
    getRegionCodes(regions, callback) {
        if (typeof regions === 'string') {
            regions = regions.split(',');
        }
        if (!regions || regions.length === 0 || !regions[0]) {
            callback([]);
            return;
        }
        this.getLocationRes(locations => {
            const codes = [];
            // 省
            for (let provinceCode in locations) {
                if (locations.hasOwnProperty(provinceCode)) {
                    if (locations[provinceCode].name === regions[0]) {
                        codes[0] = provinceCode;
                        // 市
                        const provinceContainer = locations[provinceCode].children;
                        for (let cityCode in provinceContainer) {
                            if (provinceContainer.hasOwnProperty(cityCode)) {
                                if (provinceContainer[cityCode].name === regions[1]) {
                                    codes[1] = cityCode;
                                    // 区
                                    const distContainer = provinceContainer[cityCode].children;
                                    for (let distCode in distContainer) {
                                        if (distContainer.hasOwnProperty(distCode)) {
                                            if (distContainer[distCode] === regions[2]) {
                                                codes[2] = distCode;
                                                break;
                                            }
                                            if (distContainer[distCode].name === regions[2]) {
                                                codes[2] = distCode;
                                                // 如果存在街道，继续转街道
                                                if (regions[3]) {
                                                    const streetContainer = distContainer[distCode].children;
                                                    Object.keys(streetContainer).forEach(streetCode => {
                                                        if (streetContainer[streetCode] === regions[3]) {
                                                            codes[3] = streetCode;
                                                        }
                                                    });
                                                }
                                                break;
                                            }
                                        }
                                    }
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
            }
            callback(codes);
        });
    }

}

export const $locations = new ChinaLocations();