/**
 * CGridBox
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { CGridItem } from './CGridItem';
import './cGridBox.scss';

export class CGridBox extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {};

    render() {
        const { className, children, ...resetProps } = this.props;
        return (
            <Row
                className={['c-grid-row', className].join(' ')}
                gutter={[10, 10]}
                {...resetProps}
            >
                {children}
            </Row>
        );
    }

    static Item = CGridItem;
}
