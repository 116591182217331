/**
 * CPrinter
 * @author Tevin
 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CItemEditor } from '@components/plugins/itemEditor/CItemEditor';
import { Tools } from '@components/common/Tools';
import './cPrinter.scss';

export class CPrinter extends React.Component {
    static propTypes = {
        // 打印模式：print 打印、preview 预览
        mode: PropTypes.oneOf(['print', 'preview']),
        // 占位按钮样式名
        className: PropTypes.string,
        // 占位按钮大小
        size: PropTypes.string,
        // 占位按钮文字
        placeholder: PropTypes.string,
        // 预览模式弹窗标题
        previewTitle: PropTypes.node,
        // 预览模式弹窗宽度
        previewWidth: PropTypes.number,
        // 预览模式弹窗底部
        previewFooter: PropTypes.node,
        // 加载打印内容
        onLoadPrintContent: PropTypes.func,
        // 打印完成回调
        onPrintComplete: PropTypes.func,
    };

    static defaultProps = {
        mode: 'print',
        size: 'small',
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            frameH: window.innerHeight - 200,
        };
        this.$refs = {
            iframe: null,
            preview: null,
            container: null,
        };
        this._listeners = {};
    }

    componentDidMount() {
        if (this.props.mode === 'preview') {
            this._listeners.onResize = () => {
                this.setState({ frameH: window.innerHeight - 200 });
            };
            window.addEventListener('resize', this._listeners.onResize);
        }
    }

    componentWillUnmount() {
        if (this.props.mode === 'preview') {
            window.removeEventListener('resize', this._listeners.onResize);
        }
        this.$refs.container = null;
    }

    _handleCreatePage(callback) {
        const win = this.$refs.iframe.contentWindow;
        const doc = win.document;
        // 重置
        doc.querySelectorAll('style,div.container').forEach(dom => {
            dom.parentNode.removeChild(dom);
        });
        // 添加加载样式
        const style = doc.createElement('style');
        style.innerText = defaultStyle.replace(/[\n\r]/g, '').replace(/ {2,}/g, ' ');
        doc.getElementsByTagName('head')[0].appendChild(style);
        // 添加容器
        this.$refs.container = doc.createElement('div');
        this.$refs.container.className = 'container ' + this.props.mode;
        doc.getElementsByTagName('body')[0].appendChild(this.$refs.container);
        // 初始提示
        ReactDOM.render(
            <div className="loading">加载中，请稍后...</div>,
            this.$refs.container,
        );
        // 加载内容
        this.props.onLoadPrintContent(({ content, styles }) => {
            // 添加样式
            const style = doc.createElement('style');
            style.innerText = styles.replace(/[\n\r]/g, '').replace(/ {2,}/g, ' ');
            doc.getElementsByTagName('head')[0].appendChild(style);
            // 渲染内容
            ReactDOM.render(content, this.$refs.container);
            // 下一步
            if (this.props.mode === 'print') {
                this._checkImgLoaded(doc, callback);
            }
        });
    }

    _checkImgLoaded(doc, callback) {
        const imgs = doc.querySelectorAll('img');
        if (imgs.length === 0) {
            setTimeout(() => {
                callback && callback();
            }, 10);
        } else if (imgs.length > 0) {
            let count = imgs.length;
            imgs.forEach(img => {
                Tools.loadImg(img.src, () => {
                    count--;
                    if (count <= 0) {
                        callback && callback();
                    }
                });
            });
        }
    }

    _handlePrint() {
        this.setState({ loading: true });
        this._handleCreatePage(() => {
            this.setState({ loading: false });
            setTimeout(() => {
                const win = this.$refs.iframe.contentWindow;
                win.focus();
                win.print();
                this.props.onPrintComplete && this.props.onPrintComplete();
            }, 100);
        });
    }

    _handlePreview() {
        this._handleCreatePage();
    }

    _renderPrint() {
        return (
            <iframe
                title="PrintIframe"
                className="c-printer-iframe-print"
                ref={elm => (this.$refs.iframe = elm)}
                src={navigator.userAgent.match('Firefox') ? 'javascript:' : ''}
            ></iframe>
        );
    }

    _renderPreview() {
        const showFooter = !!this.props.previewFooter;
        return (
            <>
                <iframe
                    title="PreviewIframe"
                    className={[
                        'c-printer-iframe-preview',
                        showFooter ? 'on-footer' : '',
                    ].join(' ')}
                    style={{ height: this.state.frameH + (showFooter ? -60 : 0) }}
                    ref={elm => (this.$refs.iframe = elm)}
                    src={navigator.userAgent.match('Firefox') ? 'javascript:' : ''}
                ></iframe>
                {(() => {
                    if (!showFooter) {
                        return null;
                    }
                    return (
                        <div className="c-printer-preview-footer">
                            {this.props.previewFooter || null}
                        </div>
                    );
                })()}
            </>
        );
    }

    render() {
        if (this.props.mode === 'print') {
            return (
                <div className="c-printer c-printer-print">
                    <Button
                        type="primary"
                        className={this.props.className}
                        size={this.props.size}
                        onClick={evt => this._handlePrint()}
                    >
                        {this.state.loading ? (
                            <>
                                <LoadingOutlined spin /> 准备中
                            </>
                        ) : (
                            this.props.placeholder || '打印'
                        )}
                    </Button>
                    <div className="c-printer-print-ground">{this._renderPrint()}</div>
                </div>
            );
        } else if (this.props.mode === 'preview') {
            return (
                <CItemEditor
                    className={[
                        'c-printer',
                        'c-printer-preview',
                        this.props.className,
                    ].join(' ')}
                    modleClassName="c-printer-privew-modal"
                    title={<>【预览模式】 {this.props.previewTitle}</>}
                    content={this._renderPreview()}
                    placeholderType="primary"
                    size={this.props.size}
                    width={this.props.previewWidth}
                    ref={elm => (this.$refs.preview = elm)}
                    onOpenChange={evt => evt && this._handlePreview()}
                >
                    {this.props.placeholder || '预览'}
                </CItemEditor>
            );
        }
    }

    $close() {
        if (this.props.mode === 'print') {
            return;
        } else if (this.props.mode === 'preview') {
            this.$refs.preview.$close();
        }
    }

    $print() {
        if (this.props.mode === 'print') {
            this._handlePrint();
        } else if (this.props.mode === 'preview') {
            this.$refs.preview.$open();
        }
    }
}

// 加载中样式
const defaultStyle = `
html,
body {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Hiragino Sans GB', Arial, "Microsoft YaHei", "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
}
.loading {
    text-align: center;
    padding: 100px 0;
    font-size: 18px;
    color: #999;
}
`;
