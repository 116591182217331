/**
 * CHelper
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './cHelper.scss';

export class CHelper extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
        // 提示文本
        tip: PropTypes.node,
    };

    static defaultProps = {};

    render() {
        return (
            <Tooltip className="c-helper" placement="top" title={this.props.tip}>
                {this.props.children}
                <QuestionCircleOutlined />
            </Tooltip>
        );
    }
}
