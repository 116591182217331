/**
 * CDatePicker
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { CRangeTypeRadio } from './CRangeTypeRadio';
import { CDateTimeRangePicker } from './CDateTimeRangePicker';
import { CHourRangePicker } from './CHourRangePicker';
import './cDatePicker.scss';

export class CDatePicker extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 样式
        style: PropTypes.object,
        // 选择器模式，单天选择器 or 范围选择器
        type: PropTypes.oneOf(['day', 'range']),
        // 选择器类型，选日期、选周、选月
        picker: PropTypes.oneOf(['date', 'week', 'month']),
        // 预设范围类型，仅范围选择器有效，默认参照 defaultRangeTypes
        rangeTypes: PropTypes.any,
        // 限制最多可选天数，仅范围选择器有效
        limitDays: PropTypes.number,
        // 显示时分秒，仅单天选择器有效
        showTime: PropTypes.bool,
        // 锁定的日期不允许选择，today&before、tomorrow&after
        lockDate: PropTypes.string,
        // 清除按钮
        allowClear: PropTypes.bool,
        // 占位提示
        placeholder: PropTypes.string,
        // 禁用
        disabled: PropTypes.bool,
        // 值
        value: PropTypes.string,
        // 操作回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        type: 'day',
        picker: 'date',
        defaultRangeTypes: {
            date: ['today', 'curMonth', 'lastMonth', 'nearly30', 'nearly90'],
            week: ['curWeek', 'lastWeek', 'nearly4W', 'nearly12W'],
            month: ['curMonth', 'lastMonth', 'curQuarter', 'lastQuarter', 'nearly3M'],
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            rangeTypes:
                this.props.rangeTypes || this.props.defaultRangeTypes[this.props.picker],
            value: null,
            // 当前已选的日期，开始日期与结束日期两项，计算禁用天数专用
            pickDates: null,
        };
        this._data = {
            ranges: {},
            // 上次选择器类型
            lastPikcer: this.props.picker,
            // 已选日期对象缓存
            dates: null,
            // 范围选择首次选择的类型
            rangeFirstSide: '',
            // 上次日期变更(计算当前变更是开始还是结束时间)
            lastDateStr: [],
            // 锁定日期缓存
            lockDay: {
                today: null,
            },
        };
        moment.locale('zh-cn');
    }

    componentDidMount() {
        this._data.lastDateStr = this.props.value ? this.props.value.split(',') : [];
    }

    _createRanges() {
        const ranges = {};
        for (let typeName of this.state.rangeTypes) {
            if (typeof this._data.ranges[typeName] === 'undefined') {
                switch (typeName) {
                    case 'today':
                        this._data.ranges[typeName] = {
                            label: '今天',
                            value: [moment(), moment()],
                        };
                        break;
                    case 'yesterday':
                        this._data.ranges[typeName] = {
                            label: '昨天',
                            value: [
                                moment().subtract(1, 'day'),
                                moment().subtract(1, 'day'),
                            ],
                        };
                        break;
                    case 'curWeek':
                        if (this.props.limitDays && this.props.limitDays < 7) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '本周',
                            value: [moment().startOf('week'), moment().endOf('week')],
                        };
                        break;
                    case 'lastWeek':
                        if (this.props.limitDays && this.props.limitDays < 7) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '上周',
                            value: [
                                moment().subtract(1, 'week').startOf('week'),
                                moment().subtract(1, 'week').endOf('week'),
                            ],
                        };
                        break;
                    case 'curMonth':
                        if (this.props.limitDays && this.props.limitDays < 30) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '本月',
                            value: [moment().startOf('month'), moment().endOf('month')],
                        };
                        break;
                    case 'lastMonth':
                        if (this.props.limitDays && this.props.limitDays < 30) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '上月',
                            value: [
                                moment().subtract(1, 'months').startOf('month'),
                                moment().subtract(1, 'months').endOf('month'),
                            ],
                        };
                        break;
                    case 'curQuarter':
                        if (this.props.limitDays && this.props.limitDays < 90) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '本季',
                            value: [
                                moment().startOf('quarter'),
                                moment().endOf('quarter'),
                            ],
                        };
                        break;
                    case 'lastQuarter':
                        if (this.props.limitDays && this.props.limitDays < 90) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '上季',
                            value: [
                                moment().subtract(1, 'quarter').startOf('quarter'),
                                moment().subtract(1, 'quarter').endOf('quarter'),
                            ],
                        };
                        break;
                    case 'nearly7':
                        if (this.props.limitDays && this.props.limitDays < 7) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近7天',
                            value: [moment().subtract(6, 'day'), moment()],
                        };
                        break;
                    case 'nearly30':
                        if (this.props.limitDays && this.props.limitDays < 30) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近30天',
                            value: [moment().subtract(29, 'day'), moment()],
                        };
                        break;
                    case 'nearly60':
                        if (this.props.limitDays && this.props.limitDays < 60) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近60天',
                            value: [moment().subtract(59, 'day'), moment()],
                        };
                        break;
                    case 'nearly90':
                        if (this.props.limitDays && this.props.limitDays < 90) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近90天',
                            value: [moment().subtract(89, 'day'), moment()],
                        };
                        break;
                    case 'nearly4W':
                        if (this.props.limitDays && this.props.limitDays < 28) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近4周',
                            value: [
                                moment().subtract(3, 'week').startOf('week'),
                                moment().endOf('week'),
                            ],
                        };
                        break;
                    case 'nearly12W':
                        if (this.props.limitDays && this.props.limitDays < 84) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近12周',
                            value: [
                                moment().subtract(11, 'week').startOf('week'),
                                moment().endOf('week'),
                            ],
                        };
                        break;
                    case 'nearly3M':
                        if (this.props.limitDays && this.props.limitDays < 90) {
                            break;
                        }
                        this._data.ranges[typeName] = {
                            label: '最近3个月',
                            value: [
                                moment().subtract(2, 'months').startOf('month'),
                                moment().endOf('month'),
                            ],
                        };
                        break;
                    default:
                        break;
                }
            }
            if (this._data.ranges[typeName]) {
                ranges[this._data.ranges[typeName].label] =
                    this._data.ranges[typeName].value;
            }
        }
        return ranges;
    }

    // 检测选择器类型变动
    _checkPickerTypeChange() {
        if (this._data.lastPikcer !== this.props.picker) {
            this._data.lastPikcer = this.props.picker;
            // 异步，跳出渲染栈
            setTimeout(() => {
                this.setState({
                    rangeTypes:
                        this.props.rangeTypes ||
                        this.props.defaultRangeTypes[this.props.picker],
                });
                // 异步，跳出渲染栈
                setTimeout(() => {
                    let values;
                    // 单天
                    if (this.props.type === 'day') {
                        values = this.props.value ? moment(this.props.value) : null;
                    }
                    // 范围
                    else if (this.props.type === 'range') {
                        values = this.props.value
                            ? [
                                  moment(this.props.value.split(',')[0]),
                                  moment(this.props.value.split(',')[1]),
                              ]
                            : [null, null];
                    }
                    this._handleChange(values);
                }, 10);
            }, 10);
        }
    }

    // 设置禁用的日期
    _handleDisabled(current) {
        // 范围组件如果开启了限制天数
        if (this.props.type === 'range' && this.props.limitDays) {
            // 如果已经完成了首次选择
            if (this._data.rangeFirstSide) {
                if (this.state.pickDates && this.state.pickDates.length > 0) {
                    const tooLate =
                        this.state.pickDates[0] &&
                        current.diff(this.state.pickDates[0], 'days') >
                            this.props.limitDays;
                    if (tooLate) {
                        return true;
                    }
                    const tooEarly =
                        this.state.pickDates[1] &&
                        this.state.pickDates[1].diff(current, 'days') >
                            this.props.limitDays;
                    if (tooEarly) {
                        return true;
                    }
                }
            }
        }
        // 计算锁定的日期
        if (this.props.lockDate) {
            if (!this._data.lockDay.today) {
                this._data.lockDay.today = moment().startOf('day');
            }
            const diff = Number(
                this._data.lockDay.today
                    .diff(current.startOf('day'), 'days', true)
                    .toFixed(0),
            );
            switch (this.props.lockDate) {
                // 今天及之后
                case 'today&after':
                    if (diff <= 0) {
                        return true;
                    }
                    break;
                // 明天及之后
                case 'tomorrow&after':
                    if (diff < 0) {
                        return true;
                    }
                    break;
                // 今天及之前
                case 'today&before':
                    if (diff >= 0) {
                        return true;
                    }
                    break;
                // 昨天及之前
                case 'tomorrow&before':
                    if (diff > 0) {
                        return true;
                    }
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    // 修复有天数限制时日期出界
    _fixLimitDaysOut(dates) {
        // 无限制，跳过
        if (!this.props.limitDays || !dates) {
            return;
        }
        // 变更端
        let changedSide = '';
        if (dates[0]) {
            const curStart = dates[0].format('YYYY-MM-DD');
            if (curStart !== this._data.lastDateStr[0]) {
                this._data.lastDateStr[0] = curStart;
                changedSide = 'start';
            }
        }
        if (!changedSide && dates[1]) {
            const curEnd = dates[1].format('YYYY-MM-DD');
            if (curEnd !== this._data.lastDateStr[1]) {
                this._data.lastDateStr[1] = curEnd;
                changedSide = 'end';
            }
        }
        // 设置首次选择类型
        if (!this._data.rangeFirstSide) {
            this._data.rangeFirstSide = changedSide;
        }
        // 未完成选择，跳过
        if (!dates[0] || !dates[1]) {
            return;
        }
        // 在范围内，跳过
        const difference = Math.abs(dates[0].diff(dates[1], 'days'));
        if (difference <= this.props.limitDays) {
            return;
        }
        // 开始时间的变更，置空结束
        if (changedSide === 'start') {
            dates[1] = null;
        }
        // 结束时间的变更，置空开始
        else if (changedSide === 'end') {
            dates[0] = null;
        }
    }

    // 用户操作后设置输出
    _handleChange(dates) {
        if (!dates) {
            this.props.onChange('');
            return;
        }
        // 单日期
        if (this.props.type === 'day') {
            switch (this.props.picker) {
                // 单天
                case 'date':
                    this.props.onChange(
                        this.props.showTime
                            ? dates.format('YYYY-MM-DD HH:mm:ss')
                            : dates.format('YYYY-MM-DD'),
                    );
                    break;
                // 单周
                case 'week':
                    this.props.onChange(dates.format('YYYY-Wo'));
                    break;
                // 单月
                case 'month':
                    this.props.onChange(dates.format('YYYY-MM'));
                    break;
                default:
                    break;
            }
        }
        // 范围
        else if (this.props.type === 'range') {
            this._fixLimitDaysOut(dates);
            if (dates.length !== 2 || !dates[0] || !dates[1]) {
                // 当选择未完成时，不发送改变事件
                return;
            }
            const changedValues = [];
            switch (this.props.picker) {
                // 日期范围
                case 'date':
                    changedValues[0] = dates[0].format('YYYY-MM-DD');
                    changedValues[1] = dates[1].format('YYYY-MM-DD');
                    break;
                // 周范围
                case 'week':
                    changedValues[0] = dates[0].startOf('week').format('YYYY-MM-DD');
                    changedValues[1] = dates[1].endOf('week').format('YYYY-MM-DD');
                    break;
                // 月范围
                case 'month':
                    changedValues[0] = dates[0].startOf('month').format('YYYY-MM-DD');
                    changedValues[1] = dates[1].endOf('month').format('YYYY-MM-DD');
                    break;
                default:
                    break;
            }
            this.props.onChange(changedValues.join(','));
        }
    }

    _renderExtraFooter() {
        return () => {
            if (!this.props.limitDays || this.props.limitDays <= 0) {
                return null;
            }
            return `提示：当前最多只允许选择 ${this.props.limitDays} 天`;
        };
    }

    render() {
        this._checkPickerTypeChange();
        // 单天
        if (this.props.type === 'day') {
            const format = (() => {
                switch (this.props.picker) {
                    case 'date':
                        if (this.props.showTime) {
                            return 'YYYY-M-D HH:mm:ss';
                        } else {
                            return 'YYYY-M-D';
                        }
                    case 'week':
                        return 'YYYY-Wo';
                    case 'month':
                        return 'YYYY-MM';
                    default:
                        break;
                }
            })();
            // 默认值转换
            const values = this.props.value ? moment(this.props.value) : null;
            return (
                <DatePicker
                    className="c-date-picker"
                    style={this.props.style}
                    value={values}
                    allowClear={this.props.allowClear}
                    placeholder={this.props.placeholder}
                    showTime={this.props.showTime}
                    picker={this.props.picker}
                    locale={locale}
                    format={format}
                    disabled={this.props.disabled}
                    getPopupContainer={() => document.getElementById('dialogs')}
                    onChange={evt => this._handleChange(evt)}
                />
            );
        }
        // 范围
        else if (this.props.type === 'range') {
            const format = (() => {
                switch (this.props.picker) {
                    case 'date':
                        return 'YYYY-M-D';
                    case 'week':
                        return 'YYYY-Wo';
                    case 'month':
                        return 'YYYY-MM';
                    default:
                        break;
                }
            })();
            // 默认值转换
            const valArr = this.props.value ? this.props.value.split(',') : [null, null];
            valArr[0] = !valArr[0] || valArr[0] === '0' ? null : valArr[0];
            valArr[1] = !valArr[1] || valArr[1] === '0' ? null : valArr[1];
            const values = [
                valArr[0] && moment(valArr[0]),
                valArr[1] && moment(valArr[1]),
            ];
            return (
                <DatePicker.RangePicker
                    className="c-date-picker"
                    style={this.props.style}
                    dropdownClassName={[
                        'c-date-picker-dropdown',
                        this.props.limitDays > 0 ? 'c-date-picker-limit-days' : '',
                    ].join(' ')}
                    value={values}
                    defaultPickerValue={[
                        moment().subtract(1, 'months'),
                        moment().subtract(1, 'months'),
                    ]}
                    ranges={this._createRanges()}
                    allowClear={this.props.allowClear}
                    placeholder={this.props.placeholder || ['开始日期', '结束日期']}
                    locale={locale}
                    format={format}
                    disabled={this.props.disabled}
                    picker={this.props.picker}
                    renderExtraFooter={this._renderExtraFooter()}
                    getPopupContainer={() => document.getElementById('dialogs')}
                    disabledDate={evt => this._handleDisabled(evt)}
                    onOpenChange={evt => (this._data.rangeFirstSide = '')}
                    onCalendarChange={evt => this.setState({ pickDates: evt })}
                    onChange={evt => this._handleChange(evt)}
                />
            );
        }
    }

    static CRangeTypeRadio = CRangeTypeRadio;
    static CDateTimeRangePicker = CDateTimeRangePicker;
    static CHourRangePicker = CHourRangePicker;
}
