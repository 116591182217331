/**
 * 事件巴士网络
 * 同域跨 iframe 通讯
 * @author Tevin
 */

import { Tools } from '@components/common/Tools';
import { Fetcher } from './Fetcher';

export class BusNet {

    /**
     * @constructor
     */
    constructor() {
        this._data = {
            busName: '',
            agent: window.navigator.userAgent.toLowerCase(),
            eventList: {},
            preEventList: [],
        };
        window.addEventListener('beforeunload', () => {
            this.disConnect();
        });
        window.addEventListener('unload', () => {
            this.disConnect();
        });
    }

    /**
     * 获取当前巴士实例名称
     * @return {String}
     */
    getName() {
        return this._data.busName || '';
    }

    /**
     * 连接事件网络
     * @param {String} busName
     */
    connectAs(busName) {
        if (!busName) {
            console.warn('BusNet: You must set a bus name!');
            return;
        }
        window.top['$BusNet'] = window.top['$BusNet'] || {};
        if (typeof window.top['$BusNet'][busName] === 'undefined') {
            this._data.busName = busName;
            for (let preEvent of this._data.preEventList) {
                this.on(preEvent[0], preEvent[1], preEvent[2]);
            }
            window.top['$BusNet'][busName] = (protocol, eventData, callback) => {
                if (window.closed) {
                    this.disConnect();
                } else {
                    this._execute(protocol, eventData, callback);
                }
            };
        } else {
            console.warn('BusNet: Bus name "' + busName + '" is already registered!');
        }
    }

    /**
     * 断开事件网络
     */
    disConnect() {
        if (!window.top['$BusNet']) {
            return;
        }
        delete window.top['$BusNet'][this._data.busName];
        this._data.eventList = this._data.preEventList = null;
    }

    /**
     * 执行事件（从全局网络接收）
     * @param {String} protocol
     * @param {*} eventData
     * @param {Function} callback
     * @private
     */
    _execute(protocol, eventData, callback) {
        // 完整匹配
        const listenersCur = this._data.eventList[protocol];
        // 忽略前缀匹配
        const listenersIgn = this._data.eventList['$:' + protocol.split(':')[1]];
        // 非空检查
        if ((!listenersCur && !Tools.isArray(listenersCur)) && (!listenersIgn && !Tools.isArray(listenersIgn))) {
            return;
        }
        const listeners = ([].concat(listenersCur, listenersIgn)).filter(Boolean);
        const stacks = [];
        const list = [];
        for (let listener of listeners) {
            if (listener.callback) {
                list.push(listener.callback);
                stacks.push(listener.stack);
            }
        }
        // 输出日志
        if (Fetcher.inDevMod) {
            this._log(protocol, eventData, stacks);
        }
        // 执行触发
        list.forEach((listenerFn) => {
            listenerFn(eventData, callback);
        });
    }

    /**
     * 批量注册操作事件监听
     * @param {{type:String,callback:Function}} option
     */
    register(option) {
        if (!option || Tools.isEmptyObject(option)) {
            return;
        }
        for (let type in option) {
            if (option.hasOwnProperty(type)) {
                this.on(type, option[type]);
            }
        }
    }

    /**
     * 注册事件监听
     * @param {String} type
     * @param {Function} callback
     * @param {String} [stack]
     */
    on(type, callback, stack) {
        if (!type || typeof type !== 'string') {
            return;
        }
        if (!callback) {
            return;
        }
        // log信息追加
        if (Fetcher.inDevMod && !stack) {
            try {
                // webkit
                if (this._data.agent.indexOf('applewebkit') > -1) {
                    const stacks = new Error().stack.match(/at.*?[\r\n]/g);
                    stack = (stacks[1].indexOf('register') >= 0 ? stacks[2] : stacks[1])
                        .replace('at ', '')
                        .replace(/[\r\n]/, '');
                }
                // firefox
                else if (this._data.agent.indexOf('firefox') > -1) {
                    const stacks = new Error().stack.match(/.+@.*?[\r\n]/g);
                    stack = (stacks[1].indexOf('register') >= 0 ? stacks[2] : stacks[1])
                        .replace('@', ' - ')
                        .replace(/[\r\n]/, '')
                        .replace(/:(\d+:\d+)$/, ' ($1)');
                }
            } catch (e) {
            }
        }
        // 如果事件系统还没有初始化
        if (!this._data.busName) {
            this._data.preEventList.push([type, callback, stack]);
            return;
        }
        let protocol = type.indexOf(':') > 0 ? type : (this._data.busName + ':' + type);
        const listener = {
            callback,
            stack,
        };
        if (typeof this._data.eventList[protocol] === 'undefined') {
            this._data.eventList[protocol] = [];
        }
        this._data.eventList[protocol].push(listener);
    };

    /**
     * 批量注销操作事件绑定
     * @param {Object} option
     */
    unregister(option) {
        if (!option || Tools.isEmptyObject(option)) {
            return;
        }
        for (let type in option) {
            if (option.hasOwnProperty(type)) {
                this.off(type, option[type]);
            }
        }
    }

    /**
     * 事件解除绑定
     * @param {String} type
     * @param {Function} [callback] - 不存在 callback 时解除所有 type 的绑定
     */
    off(type, callback) {
        if (!type || typeof type !== 'string') {
            return;
        }
        const protocol = type.indexOf(':') > 0 ? type : (this._data.busName + ':' + type);
        // 通用模式
        if (this._data.eventList && this._data.eventList[protocol] && this._data.eventList[protocol].length > 0) {
            // 全部释放
            if (!callback || !Tools.isFunction(callback)) {
                this._data.eventList[protocol].length = 0;
            }
            // 单个释放
            else {
                for (let i = 0, listener; (listener = this._data.eventList[protocol][i]); i++) {
                    if (listener.callback === callback) {
                        this._data.eventList[protocol].splice(i, 1);
                        break;
                    }
                }
            }
        }
    }

    /**
     * 触发事件
     * @param {String} type - 事件名称
     * @param {*} eventData - 事件携带的数据
     * @param {Function} [callback] - 回调
     */
    trigger(type, eventData, callback) {
        if (!window.top['$BusNet']) {
            return;
        }
        let protocol = '';
        // 发送给其他模块的事件
        if (type.indexOf(':') >= 0) {
            protocol = type;
        }
        // 本模块内部通讯
        else {
            protocol = this._data.busName + ':' + type;
        }
        for (let busName in window.top['$BusNet']) {
            if (window.top['$BusNet'].hasOwnProperty(busName)) {
                window.top['$BusNet'][busName](protocol, eventData, callback);
            }
        }
    }

    /**
     * 检查网络节点是否上线
     * @param {String} busName
     * @returns {Boolean}
     */
    checkBusNet(busName) {
        return typeof window.top['$BusNet'][busName] !== 'undefined';
    }

    /**
     * 在控制台打印事件日志
     * @param {String} protocol
     * @param {*} eventData
     * @param {Array} stacks
     * @private
     */
    _log(protocol, eventData, stacks) {
        const styles = {
            busName: 'padding:0 1px;color:#fff;font-weight:normal;background:#bbb;',
            className: 'padding-right:3px;margin-right:2px;color:#666;background:#f6f6f6;',
            typeName: 'color:#333;font-weight:800;',
            gray: 'color:#999999;font-weight:normal;',
            property: 'color:#666;background:#f6f6f6;',
        };
        if (protocol.indexOf('@') >= 0) {
            if (stacks.length === 0) {
                return;
            }
            const title = '%c ' + protocol
                .replace(':', ' %c: %c ')
                .replace('@', '@%c');
            console.groupCollapsed(title, styles.busName, styles.gray, styles.className, styles.typeName);
        } else {
            const [busName, typeName] = protocol.split(':');
            const title = '%c ' + busName + ' %c: %c' + typeName;
            console.groupCollapsed(title, styles.busName, styles.gray, styles.typeName);
        }
        let callerPath = '--';
        try {
            if (this._data.agent.indexOf('applewebkit') > -1) {
                callerPath = new Error().stack.match(/at.*?[\r\n]/g)[4]
                    .replace('at ', '');
            } else if (this._data.agent.indexOf('firefox') > -1) {
                callerPath = new Error().stack.match(/.+@.*?[\r\n]/g)[4]
                    .replace('@', ' - ')
                    .replace(/[\r\n]/, '')
                    .replace(/:(\d+:\d+)$/, ' ($1)');
            }
        } catch (e) {
        }
        console.log('%cfireFrom:\n', styles.gray, callerPath);
        console.log('%clisteners:\n', styles.gray, stacks.join('\n '));
        console.log('%c evetData: ', styles.property, eventData);
        console.groupEnd();
    }

}

export const $busNet = new BusNet();