/**
 * DICs 类型常量字典库
 * @author Tevin
 */

/**
 * 转换类型常量字典为数组（for筛选项）
 * @param {Object} dicObj
 * @param {String|Array} [sequence]
 * @return {[]}
 */
export const getDICArrayOptions = (dicObj, sequence = '$default') => {
    let orderArr = [];
    if (typeof sequence === 'string') {
        if (!dicObj[sequence] || dicObj[sequence].length === 0) {
            return [];
        }
        orderArr = dicObj[sequence];
    } else if (
        Object.prototype.toString.call(sequence) === '[object Array]' &&
        sequence.length > 0
    ) {
        orderArr = sequence;
    }
    const options = [];
    Object.keys(dicObj).forEach(key => {
        if (key.indexOf('$') >= 0) {
            return;
        }
        options.push({
            value: dicObj[key],
            name: dicObj['$_' + key],
        });
    });
    return orderArr.map(val => options.find(opt => opt.value === val)).filter(Boolean);
};

/**
 * 获取类型常量字典单项
 * @param {Object} dicObj
 * @param {Number} value
 * @return {{name,value}}
 */
export const findDICsItem = (dicObj, value) => {
    const item = {};
    Object.keys(dicObj).forEach(key => {
        if (key.indexOf('$') >= 0) {
            return;
        }
        if (dicObj[key] === value) {
            item.value = dicObj[key];
            item.name = dicObj['$_' + key];
            item.textType = dicObj['$$' + key] || '';
        }
    });
    return item;
};

/**
 * 员工角色
 */
export const ROLE_TYPE = {
    $default: [2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 19, 20, 21],
};
ROLE_TYPE.STATION_HEAD = 2;
ROLE_TYPE.$_STATION_HEAD = '气站站长';
ROLE_TYPE.DRIVER = 3;
ROLE_TYPE.$_DRIVER = '司机';
ROLE_TYPE.STORE_HEAD = 5;
ROLE_TYPE.$_STORE_HEAD = '门店店长';
ROLE_TYPE.DELIVERER = 6;
ROLE_TYPE.$_DELIVERER = '配送员';
ROLE_TYPE.SAFE_CHECKER = 7;
ROLE_TYPE.$_SAFE_CHECKER = '安全巡检员';
ROLE_TYPE.ALARM_INSTALLER = 8;
ROLE_TYPE.$_ALARM_INSTALLER = '报警器安装员';
ROLE_TYPE.GAS_FILLER = 9;
ROLE_TYPE.$_GAS_FILLER = '充装员';
ROLE_TYPE.REPAIR_MAN = 10;
ROLE_TYPE.$_REPAIR_MAN = '维修员';
ROLE_TYPE.SECURITY_ADMIN = 11;
ROLE_TYPE.$_SECURITY_ADMIN = '安全管理员';
ROLE_TYPE.TECHNOLOGIST = 12;
ROLE_TYPE.$_TECHNOLOGIST = '技术负责人';
ROLE_TYPE.SALES_MAN = 15;
ROLE_TYPE.$_SALES_MAN = '销售员';
ROLE_TYPE.SUPERCARGO = 16;
ROLE_TYPE.$_SUPERCARGO = '押运员';
ROLE_TYPE.FILL_CHECKER = 19;
ROLE_TYPE.$_FILL_CHECKER = '充装检查员';
ROLE_TYPE.FILL_AUDITOR = 20;
ROLE_TYPE.$_FILL_AUDITOR = '充装审核员';
ROLE_TYPE.Bottle_Files_Maintainer = 21;
ROLE_TYPE.$_Bottle_Files_Maintainer = '气瓶档案维护员';

/**
 * 订单状态
 */
export const ORDER_STATE = {
    $default: [],
};
ORDER_STATE.WX_UNPAID = -1;
ORDER_STATE.$_WX_UNPAID = '微信未支付';
ORDER_STATE.WAIT_APPOIN = 0;
ORDER_STATE.$_WAIT_APPOIN = '待指派';
ORDER_STATE.WAIT_CONFIRM = 1;
ORDER_STATE.$_WAIT_CONFIRM = '待确认';
ORDER_STATE.DELIVERING = 2;
ORDER_STATE.$_DELIVERING = '配送中';
ORDER_STATE.FINISHED = 3;
ORDER_STATE.$_FINISHED = '已送达';
ORDER_STATE.CANCELED = 4;
ORDER_STATE.$_CANCELED = '已取消';
ORDER_STATE.FINISH_ERROR = 3;
ORDER_STATE.$_FINISH_ERROR = '已完成-异常';
ORDER_STATE.FALLBACK = 6;
ORDER_STATE.$_FALLBACK = '已回退';
ORDER_STATE.WAIT_SALES_CONFIRM = 11;
ORDER_STATE.$_WAIT_SALES_CONFIRM = '待销售员确认';
ORDER_STATE.WAIT_USER_PAY = 12;
ORDER_STATE.WAIT_USER_PAY = '待用户支付';

/**
 * 订单支付方式
 */
export const PAY_TYPE = {
    $default: [1, 5, 2, 3, 4],
};
PAY_TYPE.WX = 1;
PAY_TYPE.$_WX = '微信支付';
PAY_TYPE.$$WX = 'success';
PAY_TYPE.BALANCE = 2;
PAY_TYPE.$_BALANCE = '储值支付';
PAY_TYPE.$$BALANCE = 'info';
PAY_TYPE.CASH = 3;
PAY_TYPE.$_CASH = '现金支付';
PAY_TYPE.$$CASH = 'danger';
PAY_TYPE.MONTH = 4;
PAY_TYPE.$_MONTH = '月结支付';
PAY_TYPE.$$MONTH = 'warning';
PAY_TYPE.UNION = 5;
PAY_TYPE.$_UNION = '聚合支付';
PAY_TYPE.$$UNION = 'success';

/**
 * 气瓶流转类型
 */
export const CIRC_TYPE = {
    $default: [5, 2, 1, 3, 4, 9, 10, 11, 12],
};
CIRC_TYPE.EXCHANGE = 1;
CIRC_TYPE.$_EXCHANGE = '气瓶交换';
CIRC_TYPE.DELIVERY = 2;
CIRC_TYPE.$_DELIVERY = '气瓶配送';
CIRC_TYPE.NEW_STORAGE = 3;
CIRC_TYPE.$_NEW_STORAGE = '新瓶入库';
CIRC_TYPE.$$NEW_STORAGE = 'success';
CIRC_TYPE.REFUND = 4;
CIRC_TYPE.$_REFUND = '订单退瓶'; // 订单退货退款
CIRC_TYPE.TRANSFER = 5;
CIRC_TYPE.$_TRANSFER = '后台转移';
CIRC_TYPE.$$TRANSFER = 'warning';
CIRC_TYPE.INSPECTION = 9;
CIRC_TYPE.$_INSPECTION = '送检/报废';
CIRC_TYPE.$$INSPECTION = 'danger';
CIRC_TYPE.ARCHIVECLEAR = 10;
CIRC_TYPE.$_ARCHIVECLEAR = '档案清除';
CIRC_TYPE.$$ARCHIVECLEAR = 'danger';
CIRC_TYPE.REPLACECHIP = 11;
CIRC_TYPE.$_REPLACECHIP = '更换芯片';
CIRC_TYPE.$$REPLACECHIP = 'warning';
CIRC_TYPE.ARCHIVEEDIT = 12;
CIRC_TYPE.$_ARCHIVEEDIT = '修改档案';
CIRC_TYPE.$$ARCHIVEEDIT = ' info';

/**
 * 气瓶状态
 */
export const BOTTLE_STATUS = {
    $default: [1, 4, 2, 3, 5, 6, 7],
};
BOTTLE_STATUS.NORMAL = 1;
BOTTLE_STATUS.$_NORMAL = '正常';
BOTTLE_STATUS.$$NORMAL = 'success';
BOTTLE_STATUS.EXPIRED = 4;
BOTTLE_STATUS.$_EXPIRED = '已过检';
BOTTLE_STATUS.$$EXPIRED = 'warning';
BOTTLE_STATUS.INSPECTION = 2;
BOTTLE_STATUS.$_INSPECTION = '已送检';
BOTTLE_STATUS.$$INSPECTION = 'info';
BOTTLE_STATUS.SCRAP = 3;
BOTTLE_STATUS.$_SCRAP = '已报废';
BOTTLE_STATUS.$$SCRAP = 'danger';
BOTTLE_STATUS.REMOVE = 5;
BOTTLE_STATUS.$_REMOVE = '已删除';
BOTTLE_STATUS.$$REMOVE = 'ignore';
BOTTLE_STATUS.STOPPED = 6;
BOTTLE_STATUS.$_STOPPED = '已停用';
BOTTLE_STATUS.$$STOPPED = 'ignore';
BOTTLE_STATUS.LOGGED_OUT = 7;
BOTTLE_STATUS.$_LOGGED_OUT = '已注销';
BOTTLE_STATUS.$$LOGGED_OUT = 'ignore';
