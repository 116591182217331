/**
 * CFileUploader
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload, message, Modal } from 'antd';
import {
    FileOutlined,
    FileImageOutlined,
    FileExcelOutlined,
    FilePptOutlined,
    FileWordOutlined,
    FilePdfOutlined,
    FileZipOutlined,
} from '@ant-design/icons';
import { CloudUploadOutlined } from '@ant-design/icons';
import { $fetchCommon } from '@fetchers/FCommon';
import './cFileUploader.scss';

export class CFileUploader extends React.Component {
    static propTypes = {
        // 上传文件网址
        actionUrl: PropTypes.string,
        // 允许上传类型
        accept: PropTypes.string,
        // 上传补充参数
        params: PropTypes.object,
        // 占位文本
        placeholder: PropTypes.string,
        // 是否显示上传后的文件
        showFile: PropTypes.bool,
        // 是否显示已上传文件删除按钮
        showDelete: PropTypes.bool,
        // 是否需要上传前确认
        needConfirm: PropTypes.bool,
        // 值
        value: PropTypes.string,
        // 上传前回调
        onBeforeUpload: PropTypes.func,
        // 上传回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        actionUrl:
            $fetchCommon.getUploadFileURLSpell && $fetchCommon.getUploadFileURLSpell(),
        accept: '',
        params: {},
        showFile: true,
        showDelete: true,
        needConfirm: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            uploading: false,
        };
        // 已传文件转换
        if (this.props.value) {
            this.props.value.split(',').forEach((item, index) => {
                if (!item) {
                    return;
                }
                this.state.fileList.push({
                    uid: index,
                    name: item,
                    status: 'done',
                });
            });
        }
    }

    _handleBeforeUpload(file) {
        this.setState({
            uploading: true,
        });
        // 优先使用外部确认
        if (this.props.onBeforeUpload) {
            return new Promise((resolve, reject) => {
                this.props.onBeforeUpload(isConfirmed => {
                    if (isConfirmed) {
                        resolve();
                    } else {
                        this.setState({
                            uploading: false,
                        });
                        reject();
                    }
                }, file);
            });
        }
        // 其次使用默认确认
        else if (this.props.needConfirm) {
            return new Promise((resolve, reject) => {
                Modal.confirm({
                    title: '上传文件确认',
                    content: (
                        <div className="c-file-uploader-confirm">
                            {(() => {
                                switch (file.type) {
                                    // 图片
                                    case 'image/jpeg':
                                    case 'image/png':
                                    case 'image/gif':
                                        return <FileImageOutlined />;
                                    // excel 文档
                                    case 'application/vnd.ms-excel':
                                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                                        return <FileExcelOutlined />;
                                    // ppt 文档
                                    case 'application/vnd.ms-powerpoint':
                                    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                                        return <FilePptOutlined />;
                                    // word 文档
                                    case 'application/msword':
                                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                        return <FileWordOutlined />;
                                    // PDF 文档
                                    case 'application/pdf':
                                        return <FilePdfOutlined />;
                                    // zip 文档
                                    case 'application/zip':
                                    case 'application/x-gzip':
                                    case 'application/x-rar':
                                    case 'application/x-7z-compressed':
                                    case 'application/x-zip-compressed':
                                        return <FileZipOutlined />;
                                    // 默认
                                    default:
                                        return <FileOutlined />;
                                }
                            })()}
                            <span className="file-name">{file.name}</span>
                        </div>
                    ),
                    onCancel: () => {
                        this.setState({
                            uploading: false,
                        });
                        reject();
                    },
                    onOk: () => {
                        resolve();
                    },
                });
            });
        }
        // 未设置，不需要确认
        else {
            return true;
        }
    }

    _handleUploadChange(info) {
        if (!info.file.response) {
            this.setState({ fileList: [info.file] });
        } else {
            this.setState({
                uploading: false,
            });
            const responseData = info.file.response?.data || info.file.response || {};
            const url =
                responseData.src ||
                responseData.url ||
                responseData.file ||
                responseData.fileName ||
                responseData.file_name;
            if (url) {
                this.setState({
                    fileList: [
                        {
                            uid: info.file.uid,
                            name: url || info.file.name,
                            status: info.file.status,
                        },
                    ],
                });
                message.success('文件已上传！');
                setTimeout(
                    () => {
                        this.props.onChange(url);
                    },
                    this.props.showFile ? 0 : 500,
                );
                return;
            } else {
                const responseState = info.file.response?.state || {};
                if (responseState.code && responseState.code !== 2000) {
                    const modal = Modal.error({
                        title: '文件上传失败！',
                        content: responseState.msg,
                        okButtonProps: {
                            style: { display: 'none' },
                        },
                    });
                    setTimeout(() => {
                        modal.destroy();
                    }, 5000);
                } else {
                    message.error('文件上传失败！');
                }
                this.setState({
                    fileList: [],
                });
                this.props.onChange(null);
            }
        }
    }

    _renderHelp() {
        if (!this.props.showFile) {
            return null;
        }
        if (this.state.fileList.length === 0) {
            return null;
        }
        return <span className="holder">再次上传可替换</span>;
    }

    render() {
        return (
            <Upload
                className={[
                    'c-file-uploader',
                    this.props.showDelete ? '' : 'c-file-uploader-no-delete',
                ].join(' ')}
                showUploadList={this.props.showFile}
                fileList={this.state.fileList}
                action={this.props.actionUrl}
                accept={this.props.accept}
                data={this.props.params}
                headers={{ 'Ax-Rq-Type': 'separation' }}
                beforeUpload={(file, fileList) => this._handleBeforeUpload(file)}
                onChange={evt => this._handleUploadChange(evt)}
            >
                <Button
                    type="primary"
                    loading={!this.props.showFile && this.state.uploading}
                >
                    <CloudUploadOutlined
                        style={{
                            fontSize: 16,
                            display:
                                !this.props.showFile && this.state.uploading
                                    ? 'none'
                                    : '',
                        }}
                    />
                    {this.props.placeholder}
                </Button>
                {this._renderHelp()}
            </Upload>
        );
    }
}
