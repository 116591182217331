/**
 * Fetcher Finance
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FFinance extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/finance/', '/Home/Account/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取订单待对账列表（订单对账）
    getOrderStatements(search, pagination, sorter) {
        const url = this.spellURL('getOrderStatements', 'check_order_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 保存订单对账（订单对账）
    saveOrderStatement(statement) {
        const url = this.spellURL('saveOrderStatement', 'check_record');
        const send = {
            ...this.transKeyName('underline', statement),
        };
        return this.post(url, send);
    }

    // 获取押金对账列表（押金对账）
    getDepositStatements(search, pagination, sorter) {
        const url = this.spellURL('getDepositStatements', 'check_deposit_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
            'ac_date': search.acDate?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 保存押金对账（押金对账）
    saveDepositStatement(statement) {
        const url = this.spellURL('saveDepositStatement', 'deposit_check');
        const send = {
            ...this.transKeyName('underline', statement),
        };
        return this.post(url, send);
    }

    // 获取对账单列表（对账单列表）
    getStatementsChecked(search, pagination, sorter) {
        const url = this.spellURL('getStatementsChecked', 'order_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取对账单-导出地址（对账单列表）
    getStatementsCheckedExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', 'export_check_order.html');
        const send = {
            ...search,
            'date': search.date?.replace(',', ' - '),
        };
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return this.get(url, send, null, { timeout: 300 * 1000 })
                .then(res => {
                    if (!res) {
                        return null;
                    }
                    let url2 = this.spellURL('', '../Index/downloadExport');
                    return {
                        url: this.parseParamUrl(url2, { filename: (res.file || res.data) }),
                    };
                });
        }
    }

    // 保存对账单撤销（对账单列表）
    saveCancelStatementCheck(cancel) {
        const url = this.spellURL('saveCancelStatementCheck', 'undo_account_order');
        const send = {
            ...cancel,
        };
        return this.post(url, send);
    }

    // 获取对账单详情（对账单列表）
    getStatementDetail(statement) {
        const url = this.spellURL('getStatementDetail', 'order_detail');
        const send = {
            ...statement,
        };
        return this.post(url, send);
    }

    // 获取欠款回款列表（欠款回款状态）
    getArrearsCollection(search) {
        const url = this.spellURL('getArrearsCollection', 'arrears_list');
        const send = {
            ...this.transKeyName('underline', search),
            datetime: search.datetime.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    getInventoryPurchases() {
        // TODO
        return Promise.resolve(null);
    }

    getInventorySaleness() {
        // TODO
        return Promise.resolve(null);
    }

    // 获取气瓶费用对账列表（气瓶费用对账）
    getBottleReconciliationList(search, pagination) {
        const url = this.spellURL('getBottleReconciliationList', 'use_fee_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
        };
        return this.post(url, send);
    }

    // 保存气瓶费用对账（气瓶费用对账）
    saveBottleReconciliation(data) {
        const url = this.spellURL('saveBottleReconciliation', 'usefee_check');
        const send = {
            ...this.transKeyName('underline', data),
        };
        return this.post(url, send);
    }
}

export const $fetchFinance = new FFinance();
