/**
 * CRangeTypeRadio
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

export class CRangeTypeRadio extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func,
    };

    static defaultProps = {};

    render() {
        return (
            <Radio.Group
                buttonStyle="solid"
                value={this.props.value}
                onChange={evt => this.props.onChange(evt)}
            >
                <Radio.Button value="date">按天</Radio.Button>
                <Radio.Button value="week">按周</Radio.Button>
                <Radio.Button value="month">按月</Radio.Button>
            </Radio.Group>
        );
    }
}
