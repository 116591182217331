/**
 * Fetcher Wholesale
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FWholesale extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/wholesale/', '/Home/direct/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取直销门店列表（筛选项）
    getWholesaleShopAsSelect() {
        const url = this.spellURL('getWholesaleShopFilter', 'joinShops');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    ...item,
                    name: item.shopname,
                }));
            });
    }

    // 获取直销客户列表（直销客户）
    getWholesaleCustomers(search, pagination, sorter) {
        const url = this.spellURL('getWholesaleCustomers', 'joinList');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 保存直销客户资料（直销客户）
    saveWholeCustomerInfo(customer) {
        const url = this.spellURL('saveWholeCustomerInfo', 'joinSet');
        const send = {
            ...this.transKeyName('underline', customer),
        };
        return this.post(url, send);
    }

    // 获取直销订单列表（直销订单）
    getWholesaleOrders(search, pagination, sorter) {
        const url = this.spellURL('getWholesaleOrders', 'orderList');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取直销订单详情接口（直销订单）
    getWholesaleOrderDetail(order) {
        const url = this.spellURL('getWholesaleOrderDetail', 'orderDetail');
        const send = {
            ...this.transKeyName('underline', order),
        };
        return this.post(url, send);
    }

    // 设置直销单充装重量（直销订单）
    saveWholesaleOrderWeight(order) {
        const url = this.spellURL('saveWholesaleOrderWeight', 'setFillWeight');
        const send = {
            ...this.transKeyName('underline', order),
        };
        return this.post(url, send);
    }

    // 获取直销客户月结额度（直销订单）
    getWholesaleMonthQuota(customer) {
        const url = this.spellURL('getWholesaleMonthQuota', 'getRemainLimit');
        const send = {
            ...this.transKeyName('underline', customer),
        };
        return this.post(url, send);
    }

    // 获取直销订单微信支付二维码（直销订单）
    getWholesaleOrderWxPayCode(order) {
        const url = this.spellURL('getWholesaleOrderWxPayCode', 'getWxcode');
        const send = {
            ...this.transKeyName('underline', order),
        };
        return this.post(url, send);
    }

    // 获取直销订单微信支付状态（直销订单）
    getWholesaleOrderWxPayState(order) {
        const url = this.spellURL('getWholesaleOrderWxPayState', 'getWxpayStatus');
        const send = {
            ...this.transKeyName('underline', order),
        };
        return this.post(url, send);
    }

    // 保存直销订单完整支付（直销订单）
    saveWholesaleOrderPay(pay) {
        const url = this.spellURL('saveWholesaleOrderPay', 'pay');
        const send = {
            ...this.transKeyName('underline', pay),
        };
        return this.post(url, send);
    }

}

export const $fetchWholesale = new FWholesale();

