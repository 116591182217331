/**
 * CImageViewer
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { message, Popover, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { CPlaceholderLink } from '@components/fragments/placeholderLink/CPlaceholderLink';
import { CText } from '@components/fragments/text/CText';
import Zmage from 'react-zmage';
import './cImageViewer.scss';
import failImg from './imageViewerFail.png';

export class CImageViewer extends React.Component {
    static propTypes = {
        // 单图模式图片地址
        img: PropTypes.string,
        // 多图模式图片地址
        imgs: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        // 图片描述
        alt: PropTypes.string,
        // 占位文本
        children: PropTypes.node,
        // 占位按钮类型，link 链接模式、photo 图片模式、primary/button/danger 按钮模式
        placeholderType: PropTypes.string,
    };

    static defaultProps = {
        placeholderType: 'link',
    };

    _correctImageSize(img) {
        if (img.className.indexOf('image-size-fixed') >= 0) {
            return;
        }
        const clientRect = img.getBoundingClientRect();
        if (
            clientRect.width > window.innerWidth ||
            clientRect.height > window.innerHeight
        ) {
            img.className = img.className + ' image-size-fixed';
        }
    }

    _correctFailImage(img, callback) {
        if (img.complete) {
            if (img.width === 0 || img.height === 0) {
                img.src = failImg;
                callback && callback('error');
            } else {
                this._correctImageSize(img);
                callback && callback('success');
            }
        } else {
            const img2 = new Image();
            img2.onload = () => {
                this._correctImageSize(img2);
                callback && callback('success');
            };
            img2.onerror = () => {
                img.src = failImg;
                callback && callback('error');
            };
            img2.src = img.src;
        }
    }

    _createZImage(type, imgRes, imgAlt, imgIndex) {
        const options = {
            backdrop: 'rgba(0,0,0,0.45)',
            edge: 20,
            defaultPage: imgIndex || 0,
            hotKey: {
                // 关闭（ESC）
                close: true,
                // 缩放（空格）
                zoom: true,
                // 翻页（左右）
                flip: true,
            },
            onBrowsing: browsing => {
                if (browsing) {
                    let failHas = false,
                        count = 0;
                    const imgs = document
                        .querySelector('#zmage')
                        .querySelectorAll('img[class^=imageLayer]');
                    imgs.forEach(img => {
                        this._correctFailImage(img, state => {
                            if (state === 'error') {
                                failHas = true;
                            }
                            // 等待所有图片检查完成
                            if (++count >= imgs.length - 1 && failHas) {
                                setTimeout(() => {
                                    message.error('图片加载失败！', 2);
                                }, 200);
                            }
                        });
                    });
                }
            },
            onSwitching: page => {
                document
                    .querySelector('#zmage')
                    .querySelectorAll('img[class^=imageLayer]')
                    .forEach(img => this._correctFailImage(img));
            },
        };
        if (type === 'single') {
            options.src = imgRes;
            options.alt = imgAlt;
        } else if (type === 'list') {
            options.set = [];
            imgRes.forEach((imgSrc, index) => {
                options.set.push({
                    src: imgSrc,
                    alt: imgAlt + '-' + (index + 1),
                });
            });
        }
        Zmage.browsing(options);
    }

    _renderWarning() {
        return (
            <span className="c-image-viewer-warning">
                <CloseCircleOutlined />
                <span>当前未上传图片</span>
            </span>
        );
    }

    _renderEmptyImg() {
        if (this.props.placeholderType === 'link') {
            return <span>{this.props.children}</span>;
        } else if (this.props.placeholderType === 'photo') {
            return <CText type="ignore">（暂无图片）</CText>;
        } else {
            return (
                <Popover
                    className="c-image-viewer-text"
                    placement="topLeft"
                    content={this._renderWarning()}
                    trigger="click"
                >
                    <Button type={this.props.placeholderType} size="small">
                        {this.props.children}
                    </Button>
                </Popover>
            );
        }
    }

    _renderSingleImg(imgSrc, imgAlt) {
        // 图片网址正常
        if (imgSrc && imgSrc.length > 8) {
            if (this.props.placeholderType === 'link') {
                return (
                    <CPlaceholderLink
                        className={['c-image-viewer-text', this.props.className].join(
                            ' ',
                        )}
                        text={this.props.children}
                        onClick={evt => this._createZImage('single', imgSrc, imgAlt)}
                    />
                );
            } else if (this.props.placeholderType === 'photo') {
                return (
                    <div className="c-image-viewer-preview-single">
                        <img
                            src={imgSrc}
                            alt={imgAlt}
                            onClick={evt => this._createZImage('single', imgSrc, imgAlt)}
                        />
                    </div>
                );
            } else {
                return (
                    <Button
                        className="c-image-viewer-text"
                        type={this.props.placeholderType}
                        size="small"
                        onClick={evt => this._createZImage('single', imgSrc, imgAlt)}
                    >
                        {this.props.children}
                    </Button>
                );
            }
        }
        // 图片网址为空
        else {
            return this._renderEmptyImg();
        }
    }

    _renderListImg(imgList, imgAlt) {
        if (typeof imgList === 'string' && imgList.length > 8) {
            imgList = this.props.imgs.split(',').filter(Boolean);
        } else if (imgList && imgList.length && imgList.length > 0) {
            imgList = this.props.imgs.filter(Boolean);
        } else {
            imgList = [];
        }
        if (imgList && imgList.length > 0) {
            if (this.props.placeholderType === 'link') {
                return (
                    <CPlaceholderLink
                        className={['c-image-viewer-text', this.props.className].join(
                            ' ',
                        )}
                        text={this.props.children}
                        onClick={evt => this._createZImage('list', imgList, imgAlt)}
                    />
                );
            } else if (this.props.placeholderType === 'photo') {
                return (
                    <div className="c-image-viewer-preview-list">
                        {(() =>
                            imgList.map((imgSrc, index) => (
                                <img
                                    key={index}
                                    src={imgSrc}
                                    alt={imgAlt}
                                    onClick={evt =>
                                        this._createZImage('list', imgList, imgAlt, index)
                                    }
                                />
                            )))()}
                    </div>
                );
            } else {
                return (
                    <Button
                        className="c-image-viewer-text"
                        type={this.props.placeholderType}
                        size="small"
                        onClick={evt => this._createZImage('list', imgList, imgAlt)}
                    >
                        {this.props.children}
                    </Button>
                );
            }
        } else {
            return this._renderEmptyImg();
        }
    }

    render() {
        // 单图
        if (typeof this.props.img !== 'undefined') {
            return this._renderSingleImg(this.props.img, this.props.alt);
        }
        // 多图
        else if (typeof this.props.imgs !== 'undefined') {
            return this._renderListImg(this.props.imgs, this.props.alt);
        }
        // 无图
        else {
            return this._renderEmptyImg();
        }
    }
}
