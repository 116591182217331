/**
 * CIconDetailExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconDetailExport extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span className={['anticon', this.props.className].join(' ')}>
                <svg
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        d="M480 640s0 12.8 6.4 12.8c70.4-281.6 256-268.8 256-268.8 19.2 0 32 12.8 25.6 32v70.4c0 19.2 6.4 19.2 19.2 6.4l224-204.8c12.8-6.4 12.8-12.8 19.2-19.2 0-6.4 0-12.8-6.4-19.2L800 51.2c-12.8-12.8-19.2-6.4-19.2 6.4V128c0 6.4-6.4 19.2-12.8 25.6 0 0-19.2 6.4-25.6 12.8C678.4 185.6 403.2 320 480 640z"
                        p-id="28592"
                    ></path>
                    <path
                        d="M985.6 544c-19.2 0-32 12.8-38.4 32v236.8c0 51.2-38.4 96-89.6 96H230.4c-51.2 0-89.6-44.8-89.6-96V211.2c0-51.2 38.4-96 89.6-96h230.4c19.2 0 32-19.2 32-38.4s-12.8-38.4-32-38.4H217.6c-83.2 0-153.6 70.4-153.6 160v627.2c0 89.6 70.4 160 153.6 160h665.6c83.2 0 153.6-70.4 153.6-160V576c-6.4-19.2-32-32-51.2-32z"
                        p-id="28593"
                    ></path>
                </svg>
            </span>
        );
    }
}
