/**
 * CDraggableModal
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CDraggation } from '@components/plugins/draggation/CDraggation';
import { Tools } from '@components/common/Tools';
import './cDraggableModal.scss';

export class CDraggableModal extends React.Component {
    static propTypes = {
        draggable: PropTypes.bool,
    };

    static defaultProps = {
        draggable: true,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.$refs = {
            moveHandler: null,
            container: null,
        };
        this._data = {
            bounds: { leftMin: 0, leftMax: 0, topMin: 0, topMax: 0 },
            startX: 0,
            startY: 0,
            lastPosi: { x: 0, y: 0 },
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _getMoveDelta(evt) {
        let deltaX = evt.clientX - this._data.startX + this._data.lastPosi.x;
        deltaX = Math.max(this._data.bounds.leftMin, deltaX);
        deltaX = Math.min(this._data.bounds.leftMax, deltaX);
        let deltaY = evt.clientY - this._data.startY + this._data.lastPosi.y;
        deltaY = Math.max(this._data.bounds.topMin, deltaY);
        deltaY = Math.min(this._data.bounds.topMax, deltaY);
        return { deltaX, deltaY };
    }

    _handleDragging(evt) {
        if (evt.type !== 'dragging') {
            return;
        }
        if (evt.dragType === 'dragStart') {
            this._handleStart();
            this._data.startX = evt.clientX;
            this._data.startY = evt.clientY;
        } else if (evt.dragType === 'dragMove') {
            const { deltaX, deltaY } = this._getMoveDelta(evt);
            Tools.setElementStyles(this.$refs.container, {
                top: deltaY + 'px',
                left: deltaX + 'px',
            });
        } else if (evt.dragType === 'dragEnd') {
            this._handleEnd();
            const { deltaX, deltaY } = this._getMoveDelta(evt);
            this._data.lastPosi.x = deltaX;
            this._data.lastPosi.y = deltaY;
        }
    }

    _handleStart() {
        let tempElm = this.$refs.moveHandler;
        while (tempElm.parentElement) {
            if (tempElm.parentElement.getAttribute('role') === 'document') {
                this.$refs.container = tempElm.parentElement;
                break;
            } else {
                tempElm = tempElm.parentElement;
            }
        }
        if (!this.$refs.container) {
            return;
        }
        const targetRect = this.$refs.container.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        const { clientWidth, clientHeight } = window.document.documentElement;
        this._data.bounds = {
            leftMin: -(clientWidth - targetRect.width) / 2 + 8,
            leftMax: (clientWidth - targetRect.width) / 2 - 8,
            topMin: -(clientHeight - targetRect.height) / 2,
            topMax: (clientHeight - targetRect.height) / 2,
        };
    }

    _handleEnd() {
        this.$refs.container = null;
    }

    _renderTitle() {
        return (
            <>
                <CDraggation onDragging={evt => this._handleDragging(evt)}>
                    <div
                        className="c-draggable-modal-handler"
                        ref={elm => (this.$refs.moveHandler = elm)}
                    ></div>
                </CDraggation>
                <div className="c-draggable-modal-title">{this.props.title}</div>
            </>
        );
    }

    render() {
        const { wrapClassName, title, children, ...resetProps } = this.props;
        return (
            <Modal
                {...resetProps}
                wrapClassName={[wrapClassName, 'c-draggable-modal'].join(' ')}
                title={this._renderTitle()}
            >
                {this.props.children}
            </Modal>
        );
    }
}
