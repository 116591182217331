/**
 * CText
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import './cText.scss';

export class CText extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 样式
        style: PropTypes.object,
        // 模式
        mode: PropTypes.oneOf(['words', 'money', 'number', 'adjustMoney']),
        // html 标签的 title 属性
        title: PropTypes.string,
        // 样色类型，包含 success、info、warning、danger、ignore 5种
        type: PropTypes.string,
        // 内容
        children: PropTypes.node,
    };

    static defaultProps = {
        mode: 'words',
    };

    _renderWords() {
        return (
            <span
                className={[
                    'c-text',
                    this.props.type ? 'c-text-' + this.props.type : '',
                    this.props.className,
                ].join(' ')}
                style={this.props.style}
                title={this.props.title}
            >
                {this.props.children}
            </span>
        );
    }

    // 普通金额
    _renderMoney() {
        if (React.isValidElement(this.props.children)) {
            return this._renderWords();
        }
        const money = Number(this.props.children || 0);
        let type = '';
        if (money === 0) {
            type = 'ignore';
        }
        return (
            <span
                className={[
                    'c-text',
                    type ? 'c-text-' + type : '',
                    this.props.className,
                ].join(' ')}
                style={this.props.style}
                title={this.props.title}
            >
                {this.props.children}
            </span>
        );
    }

    // 调价金额
    _renderReduceMoney() {
        if (React.isValidElement(this.props.children)) {
            return this._renderWords();
        }
        const money = Number(this.props.children || 0);
        // 降价
        if (money < 0) {
            return (
                <span
                    className={['c-text-reduce-money', this.props.className].join(' ')}
                    style={this.props.style}
                    title={this.props.title}
                >
                    <span className="c-text">{this.props.children}</span>
                    <span className="no-print"> (降价)</span>
                </span>
            );
        }
        // 未调价
        else if (money === 0) {
            return (
                <span className="c-text-reduce-money">
                    <span
                        className={['c-text', 'c-text-ignore', this.props.className].join(
                            ' '
                        )}
                        style={this.props.style}
                        title={this.props.title}
                    >
                        {this.props.children}
                    </span>
                </span>
            );
        }
        // 涨价
        else if (money > 0) {
            return (
                <span
                    className={['c-text-reduce-money', this.props.className].join(' ')}
                    style={this.props.style}
                    title={this.props.title}
                >
                    <span>{this.props.children}</span>
                    <span className="c-text c-text-info no-print"> (涨价)</span>
                </span>
            );
        }
    }

    render() {
        switch (this.props.mode) {
            case 'money':
            case 'number':
                return this._renderMoney();
            case 'adjustMoney':
                return this._renderReduceMoney();
            case 'words':
            default:
                return this._renderWords();
        }
    }
}
