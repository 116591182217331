/**
 * CPlaceholderLink
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { EditOutlined, SearchOutlined, LinkOutlined } from '@ant-design/icons';
import './cPlaceholderLink.scss';

export class CPlaceholderLink extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 样式
        style: PropTypes.object,
        // 显示类型
        type: PropTypes.string,
        // 是否为警告色
        danger: PropTypes.bool,
        // 是否禁用
        disabled: PropTypes.bool,
        // 显示文本
        text: PropTypes.node,
        // 图标
        icon: PropTypes.node, // 字符串 or React 元素
        // 点击
        onClick: PropTypes.func,
    };

    static defaultProps = {
        danger: false,
        disabled: false,
        icon: 'viewer',
        type: 'link',
    };

    _renderIcon() {
        if (typeof this.props.icon === 'string') {
            switch (this.props.icon) {
                case 'editor':
                    return <EditOutlined />;
                case 'viewer':
                    return <SearchOutlined />;
                case 'jumper':
                    return <LinkOutlined />;
                default:
                    return null;
            }
        } else {
            return this.props.icon;
        }
    }

    render() {
        return (
            <Button
                className={['c-placeholder-link', this.props.className || ''].join(' ')}
                type={this.props.type}
                size="small"
                danger={this.props.danger}
                disabled={this.props.disabled}
                style={this.props.style}
                onClick={evt => this.props.onClick && this.props.onClick(evt)}
            >
                <span className="text">{this.props.text}</span>
                {this._renderIcon()}
            </Button>
        );
    }
}
