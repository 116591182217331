/**
 * CMultipleSelect
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

export class CMultipleSelect extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 行内样式
        style: PropTypes.object,
        // 输入框清空按钮
        allowClear: PropTypes.bool,
        // 是否禁用
        disabled: PropTypes.bool,
        // 占位提示
        placeholder: PropTypes.string,
        // 下拉框选项列队
        options: PropTypes.array.isRequired,
        // 项值
        value: PropTypes.array,
        // 初始项值
        defaultValue: PropTypes.array,
        // 改变项值后的回调
        onChange: PropTypes.func,
        // 增加选中项的回调
        onAdd: PropTypes.func,
        // 删除选中项的回调
        onDelete: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
        allowClear: false,
    };

    _renderItemShow(item) {
        if (item.explain) {
            return (
                <>
                    {item.name}
                    <span style={{ marginLeft: 20, color: '#888' }}>
                        ( {item.explain} )
                    </span>
                </>
            );
        } else {
            return item.name;
        }
    }

    render() {
        return (
            <span className={['c-multiple-selector', this.props.className].join(' ')}>
                <Select
                    mode="multiple"
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    allowClear={this.props.allowClear}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    optionLabelProp="label"
                    showArrow={true}
                    showSearch={false}
                    onSelect={evt => this.props.onAdd && this.props.onAdd(evt)}
                    onDeselect={evt => this.props.onDelete && this.props.onDelete(evt)}
                    onChange={evt => this.props.onChange && this.props.onChange(evt)}
                >
                    {this.props.options
                        ? this.props.options.map((item, index) => {
                              let value;
                              let disabled = false;
                              if (typeof item.value !== 'undefined') {
                                  value = item.value;
                              } else if (typeof item.id !== 'undefined') {
                                  value = item.id;
                              } else {
                                  value = index;
                              }
                              if (typeof item.disabled !== 'undefined') {
                                  disabled = item.disabled;
                              }
                              return (
                                  <Select.Option
                                      value={value}
                                      key={value}
                                      label={item.name}
                                      disabled={disabled}
                                  >
                                      {this._renderItemShow(item)}
                                  </Select.Option>
                              );
                          })
                        : null}
                </Select>
            </span>
        );
    }
}
