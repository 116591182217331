/**
 * CScreenGrid
 * @author Tevin
 * @tutorial
 *  列表：
 *      xs  屏幕 < 576px
 *      sm	屏幕 ≥ 576px
 *      md	屏幕 ≥ 768px
 *      lg	屏幕 ≥ 992px
 *      xl	屏幕 ≥ 1200px
 *      xxl	屏幕 ≥ 1600px
 *  分段：
 *      xs < 576px ≥ sm < 768px ≥ md < 992px ≥ lg < 1200px ≥ xl < 1600px ≥ xxl
 */

import React from 'react';
import PropTypes from 'prop-types';
import {} from 'antd';
import ResponsiveObserve from 'antd/lib/_util/responsiveObserve';

export class CScreenGrid extends React.Component {
    static propTypes = {
        // 设置断点
        breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
        // 断点响应
        onBreakpoint: PropTypes.func,
        // 屏幕变化响应
        onScreenChange: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
        this._data = {
            screensStr: '{}',
            timer: 0,
        };
    }

    componentDidMount() {
        ResponsiveObserve.subscribe(screens => {
            const screenStr = JSON.stringify(screens || {});
            if (screenStr === this._data.screensStr) {
                return;
            }
            const lastScreen = JSON.parse(this._data.screensStr);
            const screenEvt = {
                from: this._getScreenType(lastScreen),
                current: this._getScreenType(screens),
                screens,
            };
            this.props.onScreenChange && this.props.onScreenChange(screenEvt);
            this._handleBreakPoint(screenEvt);
            // 记录旧值
            this._data.screensStr = JSON.stringify(screens || {});
        });
    }

    componentWillUnmount() {
        ResponsiveObserve.unsubscribe();
    }

    _getScreenType(screens) {
        const screenTypes = ['sm', 'md', 'lg', 'xl', 'xxl'];
        let type = '';
        if (screens.xs) {
            type = 'xs';
        } else if (!screens.sm) {
            type = 'xs';
        } else {
            for (let sType of screenTypes) {
                if (!screens[sType]) {
                    break;
                }
                type = sType;
            }
        }
        return type;
    }

    _handleBreakPoint(screenEvt) {
        if (!this.props.breakpoint) {
            return;
        }
        const screenTypes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
        const breakIndex = screenTypes.indexOf(this.props.breakpoint);
        const currentIndex = screenTypes.indexOf(screenEvt.current);
        if (breakIndex < 0 || currentIndex < 0) {
            return;
        }
        let breakState;
        // 最小
        if (this.props.breakpoint === 'xs') {
            breakState = screenEvt.current === 'xs';
        }
        // 最大
        else if (this.props.breakpoint === 'xxl') {
            breakState = screenEvt.current === 'xxl';
        }
        // 中间
        else {
            if (breakIndex <= currentIndex) {
                breakState = true;
            } else if (currentIndex < breakIndex) {
                breakState = false;
            }
        }
        this.props.onBreakpoint && this.props.onBreakpoint(breakState);
    }

    render() {
        return null;
    }
}
