/**
 * CChinaCascader
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Cascader, Button, message } from 'antd';
import { $locations } from '@components/forms/chinaAddress/ChinaLocations';
import './cChinaCascader.scss';

export class CChinaCascader extends React.Component {
    static propTypes = {
        // 是否允许清理
        allowClear: PropTypes.bool,
        // 尺寸
        size: PropTypes.string,
        // 样式
        style: PropTypes.object,
        // 本市默认省市区存储标记，不填不显示
        defaultCityMark: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // 联动级别
        level: PropTypes.oneOf(['3', '4']),
        // 值
        value: PropTypes.string,
        // 变化回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        allowClear: false,
        level: '3',
    };

    constructor(props) {
        super(props);
        this.state = {
            locations: [],
        };
        this._data = {
            type: 'code', // code or name
            lastMark: '',
            emptyOption: [{ label: '加载中，请稍后...', value: '0' }],
        };
    }

    componentDidMount() {
        this._initLocations();
        this._screeningDefaultCity();
    }

    _initLocations() {
        $locations.getLocationDatas(this.props.level, locations => {
            this.setState({ locations });
        });
    }

    // 筛查默认城市过期
    _screeningDefaultCity() {
        if (!this.props.defaultCityMark) {
            return;
        }
        const regionsStr = localStorage['AiSim@defaultCityRegions'];
        // 不存在
        if (!regionsStr) {
            localStorage['AiSim@defaultCityRegions'] = '{}';
        }
        // 旧格式，转存
        else if (regionsStr.indexOf('{') < 0) {
            localStorage['AiSim@defaultCityRegions'] = JSON.stringify({
                [this.props.defaultCityMark]: [regionsStr, Date.now()],
            });
        }
        // 新格式
        else {
            try {
                const regionData = JSON.parse(regionsStr);
                // 当前访问更新时间
                if (typeof regionData[this.props.defaultCityMark] !== 'undefined') {
                    regionData[this.props.defaultCityMark][1] = Date.now();
                }
                // 删除超过90天数据
                const curTime = Date.now();
                Object.keys(regionData).forEach(mark => {
                    if (curTime - regionData[mark][1] > 1000 * 60 * 60 * 24 * 90) {
                        delete regionData[mark];
                    }
                });
                localStorage['AiSim@defaultCityRegions'] = JSON.stringify(regionData);
            } catch (err) {
                localStorage['AiSim@defaultCityRegions'] = '{}';
            }
        }
    }

    _triggerChange(changedValue) {
        if (!this.props.onChange) {
            return;
        }
        if (this._data.type === 'code') {
            this.props.onChange(changedValue.regions.join(','));
        } else if (this._data.type === 'name') {
            $locations.getRegionNames(changedValue.regions, names => {
                this.props.onChange(names.join(','));
            });
        }
    }

    // 设置本市默认
    _handleSaveDefaultCity() {
        const regions = JSON.parse(localStorage['AiSim@defaultCityRegions'] || '{}');
        regions[this.props.defaultCityMark] = [this.props.value, Date.now()];
        localStorage['AiSim@defaultCityRegions'] = JSON.stringify(regions);
        message.info('已保存 ' + this.props.value.replace(/,/g, '/') + ' 为本市默认地区');
    }

    // 读取本市默认
    _handleLoadDefaultCity() {
        const regions = JSON.parse(localStorage['AiSim@defaultCityRegions'] || '{}');
        if (!regions || !regions[this.props.defaultCityMark]) {
            message.warn('您尚未设置本市默认省市区！');
        } else {
            const regions2 = regions[this.props.defaultCityMark][0].split(',');
            if (/^\d+$/.test(regions2[0])) {
                this._triggerChange({ regions });
            } else {
                $locations.getRegionCodes(regions2, codes => {
                    this._triggerChange({
                        regions: codes,
                    });
                });
            }
        }
    }

    // 渲染设置本市默认
    _renderSetCityDefault() {
        if (!this.props.defaultCityMark) {
            return null;
        }
        // 已有地址
        if (this.props.value && /^.+,.+,.+$/.test(this.props.value)) {
            return (
                <Button size="small" onClick={evt => this._handleSaveDefaultCity()}>
                    存为本市默认
                </Button>
            );
        } else {
            return (
                <Button size="small" onClick={evt => this._handleLoadDefaultCity()}>
                    使用本市默认
                </Button>
            );
        }
    }

    render() {
        if (this.props.defaultCityMark !== this._data.lastMark) {
            this._data.lastMark = this.props.defaultCityMark;
            setTimeout(() => {
                this._screeningDefaultCity();
            }, 100);
        }
        const regions = this.props.value ? this.props.value.split(',') : [];
        let codes = [];
        // 数值类型，视为省市区code
        if (/^\d+$/.test(regions[0])) {
            this._data.type = 'code';
            codes = regions;
        }
        // 文本类型，转code
        else {
            this._data.type = 'name';
            $locations.getRegionCodes(regions, codeRes => {
                codes = codeRes;
            });
        }
        const options =
            this.state.locations.length > 0
                ? this.state.locations
                : this._data.emptyOption;
        return (
            <div
                className={[
                    'c-china-address',
                    this.props.defaultCityMark ? 'show-default-city' : '',
                ].join(' ')}
                style={this.props.style}
            >
                <Cascader
                    options={options}
                    value={codes}
                    allowClear={this.props.allowClear}
                    placeholder="请选择省市区"
                    changeOnSelect
                    onChange={evt => this._triggerChange({ regions: evt })}
                />
                {this._renderSetCityDefault()}
            </div>
        );
    }
}
