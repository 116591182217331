/**
 * CSelectBox
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';
import './cSelectBox.scss';

export class CSelectBox extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
        // 主要内容
        title: PropTypes.node,
        // 补充内容
        tips: PropTypes.node,
        // 是否选中
        checked: PropTypes.bool,
        // 是否锁定
        locked: PropTypes.bool,
        // 点击
        onClick: PropTypes.func,
    };

    static defaultProps = {};

    _renderTips() {
        if (!this.props.tips) {
            return null;
        } else {
            return <span className="tips">{this.props.tips}</span>;
        }
    }

    render() {
        return (
            <div
                className={[
                    'c-select-box',
                    this.props.checked ? 'checked' : '',
                    this.props.locked ? 'locked' : '',
                ].join(' ')}
                onClick={evt => {
                    if (!this.props.locked) {
                        this.props.onClick && this.props.onClick(evt);
                    }
                }}
            >
                <span className="title">{this.props.title}</span>
                {this._renderTips()}
                <CheckOutlined />
            </div>
        );
    }
}
