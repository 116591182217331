/**
 * Fetcher Common
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FCommon extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/common/', '/Home/common/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 通用图片上传地址
    getUploadImageURLSpell(type = 'temporary') {
        // 上传到临时文件夹，后续业务接口转移到正式文件夹
        if (!type || type === 'temporary') {
            return this.spellURL('uploadImage', '../Upload/upload');
        }
        // 直接上传到正式文件夹，对于会从临时文件夹转移文件的接口会报错
        else if (type === 'formal') {
            return this.spellURL('uploadImage', '../Upload/upload_img');
        }
    }

    // 保存图片物理删除（公用）
    saveRemoveImage(file) {
        const url = this.spellURL('removeImage', '../Upload/delete_img');
        const send = {
            ...this.transKeyName('underline', file),
        };
        return this.post(url, send);
    }

    // 通用文件上传地址
    getUploadFileURLSpell() {
        return this.spellURL('uploadFile', '../Upload/upload');
    }

    // 获取目录树（全局）
    getMenuTree() {
        const url = this.spellURL('getMenuTree', '../Shop/getMenu');
        const send = {};
        return this.post(url, send);
    }

    // 绑定 websocket 客户端 id（全局）
    bindWSClientId(client) {
        const url = this.spellURL('bindWSClientId', '../Shop/setGetType');
        const send = {
            ...this.transKeyName('underline', client),
            uid: client.clientId,
        };
        delete send.clientId;
        return this.post(url, send);
    }

    // 获取无门店会员数（个人主页）
    getNoStoreUser() {
        const url = this.spellURL('../user/getUsersList', '../User/user_list');
        const send = {
            page: 1,
            limit: 1,
            store: 0,
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return res.total;
            });
    }

    // 获取系统通知（个人主页）
    getSystemNotification() {
        const url = this.spellURL('getSystemNotification');
        const send = {};
        return this.post(url, send);
    }

    // 查询端口数不足
    getAppPortCountState() {
        const url = this.spellURL('getAppPortCountState', '../Shop/getNoticeList');
        const send = {};
        return this.post(url, send);
    }

}

export const $fetchCommon = new FCommon();

