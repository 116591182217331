/**
 * Fetcher Order
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FOrder extends Fetcher {
    constructor() {
        super({
            urlPrefix: ['/api/order/', '/Home/Order/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取订单统计数据（订单统计）
    getOrderStatistic(search) {
        const url = this.spellURL('getOrderStatistic', 'order_statistic');
        const send = {
            ...search,
            datetime: search.date?.replace(',', ' - '),
        };
        delete send.date;
        return this.post(url, send);
    }

    // 获取订单单天统计分析数据（今日订单）
    getOrderStatisticOnDay(search) {
        const url = this.spellURL('getOrderStatisticOnDay', 'today_order');
        const send = {
            ...this.transKeyName('underline', search),
            type: 1,
        };
        return this.post(url, send);
    }

    // 获取订单列表（订单列表）
    getOrderList(search, pagination, sorter) {
        const url = this.spellURL('getOrderList', 'order_list');
        const send = {
            ...this.transKeyName('underline', search),
            tradeNo: search.tradeNo,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: {
                money: 'money',
                voucher: 'voucher',
                status: 'status',
                createtime: 'createtime',
                okTime: 'ok_time',
                judgeBottle: 'judge_bottle',
                judgeService: 'judge_service',
                deliveryTime: 'delivery_time',
                shopname: 'store',
            }[sorter.field],
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        if (search.tradeNo) {
            send.tradeNo = search.tradeNo;
        }
        delete send['trade_no'];
        return this.post(url, send, [
            ['', 'count', 'total'],
            ['', 'orders', 'rows'],
        ]);
    }

    // 获取订单列表-导出地址（订单列表）
    getOrderListExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', 'export_order');
        const send = {
            ...this.transKeyName('underline', search),
            user_name: search.name,
            order_status: search.status,
            datetime: search.datetime?.replace(',', ' - '),
        };
        delete send.name;
        delete send.status;
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    // 获取订单指派信息（订单列表）
    getOrderAppointInfo(search) {
        const url = this.spellURL('getOrderAppointInfo', 'appointOrderInfo');
        const send = {
            id: search.ordernum,
        };
        return this.post(url, send);
    }

    // 保存订单指派（订单列表）
    saveOrderAppoint(appoint) {
        const url = this.spellURL('saveOrderAppoint', 'appoint_order');
        const send = {
            ...this.transKeyName('underline', appoint),
        };
        return this.post(url, send);
    }

    // 保存设置订单门店（订单列表）
    saveOrderStore(store) {
        const url = this.spellURL('saveOrderStore', 'set_order_store');
        const send = {
            ...this.transKeyName('underline', store),
        };
        return this.post(url, send);
    }

    // 保存订单转派门店（订单列表）
    saveOrderModifyStore(store) {
        const url = this.spellURL('saveOrderModifyStore', 'transfer_store');
        const send = {
            ...this.transKeyName('underline', store),
        };
        return this.post(url, send);
    }

    // 获取订单详情（订单列表-订单详情）
    getOrderDetail(order) {
        const url = this.spellURL('getOrderDetail', 'order_detail');
        const send = {
            ...order,
        };
        return this.post(url, send);
    }

    // 获取订单优惠券（订单列表-订单详情）
    getOrderCoupons(order) {
        const url = this.spellURL('getOrderCoupons', 'order_detail_voucher');
        const send = {
            ...order,
        };
        return this.post(url, send);
    }

    // 保存取消订单（订单列表-订单详情）
    saveOrderCancel(cancel) {
        const url = this.spellURL('saveOrderCancel', 'update_order_detail');
        const send = {
            ...this.transKeyName('underline', cancel),
        };
        return this.post(url, send);
    }

    // 获取订单调价信息（订单列表-订单详情）
    getOrderAdjustInfo(order) {
        const url = this.spellURL('getOrderAdjustInfo', 'reducePriceInfo');
        const send = {
            ...order,
        };
        return this.post(url, send);
    }

    // 保存订单调价（订单列表-订单详情）
    saveOrderAdjustPrice(modify) {
        const url = this.spellURL('saveOrderAdjustPrice', 'reduce_price');
        const send = {
            ...modify,
            op_note: modify.opNote,
        };
        delete send.opNote;
        return this.post(url, send);
    }

    // 保存订单退款（订单列表-订单详情）
    saveOrderRefund(refund) {
        const url = this.spellURL('saveOrderRefund', 'return_goods');
        const send = {
            ...refund,
        };
        return this.post(url, send);
    }

    // 保存订单备注（订单列表-订单详情）
    saveOrderNote(note) {
        const url = this.spellURL('saveOrderNote', 'update_note');
        const send = {
            ...this.transKeyName('underline', note),
        };
        return this.post(url, send);
    }

    // 保存订单预约时间（订单列表-订单详情）
    saveOrderDeliveryTime(newTime) {
        const url = this.spellURL('saveOrderDeliveryTime', 'modify_delivery_time');
        const send = {
            ...this.transKeyName('underline', newTime),
        };
        return this.post(url, send);
    }

    // 获取订单气瓶使用费（订单详情）
    getOrderUseFee(order) {
        const url = this.spellURL('getOrderUseFee', 'order_use_detail');
        const send = {
            ...order,
        };
        return this.post(url, send);
    }

    // 获取微信支付状态（订单列表-订单详情）
    getOrderWxPayStatus(order) {
        const url = this.spellURL('getOrderWxPayStatus', 'wxpay_check');
        const send = {
            ...order,
        };
        return this.post(url, send);
    }

    // 获取退款订单列表（退款订单）
    getOrderRefundList(search, pagination, sorter) {
        const url = this.spellURL('getOrderRefundList', 'refund_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            create_date: search.createDate?.replace(',', ' - '),
            back_date: search.backDate?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 保存退款订单审核（退款订单）
    saveRefundOrderCheck(refund) {
        const url = this.spellURL('saveRefundOrderCheck', 'order_check');
        const send = {
            ...this.transKeyName('underline', refund),
        };
        return this.post(url, send);
    }

    // 保存退款订单退款（退款订单）
    saveRefundOrderRefund(refuse) {
        const url = this.spellURL('saveRefundOrderRefund', 'order_refund');
        const send = {
            ...this.transKeyName('underline', refuse),
        };
        return this.post(url, send);
    }

    // 保存退款订单退款（退款订单）
    saveRefundOrderRefuse(refund) {
        const url = this.spellURL('saveRefundOrderRefuse', 'refuse_order');
        const send = {
            ...this.transKeyName('underline', refund),
        };
        return this.post(url, send);
    }

    // 获取调价订单列表（调价订单）
    getAdjustPriceOrderList(search, pagination, sorter) {
        const url = this.spellURL('getAdjustPriceOrderList', 'voucher_order_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            date: search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取临时费用订单列表（临时费用订单）
    getExtraPriceOrder(search, pagination, sorter) {
        const url = this.spellURL('getExtraPriceOrder', 'extra_price_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 保存临时费用审核（临时费用订单）
    saveExtraPriceExamine(examine) {
        const url = this.spellURL('saveExtraPriceExamine', 'extra_price_api');
        const send = {
            ...this.transKeyName('underline', examine),
        };
        return this.post(url, send);
    }

    // 获取会员订单历史（添加订单）
    getUserOrder(search) {
        const url = this.spellURL('getUserOrder', 'user_order');
        const send = {
            ...search,
            user_id: search.userId,
        };
        return this.post(url, send, [
            ['rows.[]', 'createtime', 'createTime'],
            ['rows.[]', 'uid', 'uId'],
        ]);
    }

    // 获取会员支付可用优惠券列表（添加订单）
    getUserCoupons(search) {
        const url = this.spellURL('getUserCoupons', 'order_voucher');
        const send = {
            ...search,
            user_id: search.userId,
            floor_fee: search.floorFee,
        };
        return this.post(url, send);
    }

    // 获取维修工单列表（报修工单）
    getRepairOrderList(search, pagination, sorter) {
        const url = this.spellURL('getRepairOrderList', 'repair_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取报修工单详情（报修工单）
    getRepairOrderDetail(repair) {
        const url = this.spellURL('getRepairOrderDetail', 'repair_detail');
        const send = {
            ...this.transKeyName('underline', repair),
        };
        return this.post(url, send);
    }

    // 保存报修工单指派（报修工单）
    saveRepairAppoint(appoint) {
        const url = this.spellURL('saveRepairAppoint', 'appoint_repair');
        const send = {
            ...this.transKeyName('underline', appoint),
        };
        return this.post(url, send);
    }

    // 保存报修工单取消（报修工单-详情）
    saveRepairCancel(cancel) {
        const url = this.spellURL('saveRepairCancel', 'cancelRepair');
        const send = {
            ...this.transKeyName('underline', cancel),
        };
        return this.post(url, send);
    }

    // 保存报修工单备注（报修工单-详情）
    saveRepairNote(note) {
        const url = this.spellURL('saveRepairNote', 'update_repair');
        const send = {
            ...this.transKeyName('underline', note),
        };
        return this.post(url, send);
    }

    // 获取补瓶工单列表（补瓶工单）
    getReplenishOrderList(search, pagination, sorter) {
        const url = this.spellURL('getReplenishOrderList', 'replenish_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            datetime: search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取补瓶工单-导出地址（补瓶工单）
    getReplenishOrderExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', 'export_replenish.html');
        const send = {
            ...search,
        };
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return this.get(url, send, null, { timeout: 300 * 1000 }).then(res => {
                if (!res) {
                    return null;
                }
                let url2 = this.spellURL('', '../Index/downloadExport');
                return {
                    url: this.parseParamUrl(url2, { filename: res.file || res.data }),
                };
            });
        }
    }

    // 获取补瓶工单详情（补瓶工单）
    getReplenishOrderDetail(order) {
        const url = this.spellURL('getReplenishOrderDetail', 'replenish_detail');
        const send = {
            ...order,
        };
        return this.post(url, send);
    }

    // 获取补瓶工单指派信息（补瓶工单）
    getReplenishAppointInfo(appoint) {
        const url = this.spellURL('getReplenishAppointInfo', 'appointReplenishInfo');
        const send = {
            ...appoint,
        };
        return this.post(url, send);
    }

    // 保存补瓶工单指派（补瓶工单）
    saveReplenishJobAppoint(appoint) {
        const url = this.spellURL('saveReplenishJobAppoint', 'appoint_replenish');
        const send = {
            ...this.transKeyName('underline', appoint),
        };
        return this.post(url, send);
    }

    // 保存异步导出补瓶工单（补瓶工单）
    saveExportReplenishJobAppoint(search) {
        const url = this.spellURL('saveExportReplenishJobAppoint', 'exportReplenishList');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存会员换瓶单（添加订单）
    saveBottleOrder(orderInfo) {
        const url = this.spellURL('saveBottleOrder', 'add_order');
        const send = {
            ...orderInfo,
            user_id: orderInfo.userId,
            dispatch_uid: orderInfo.dispatchUid,
            delivery_time: orderInfo.deliveryTime,
            bottle_use_ids: orderInfo.bottleUseIds,
            pay: {
                ...orderInfo.pay,
                floor_fee: orderInfo.pay.floorFee,
                discount_money: orderInfo.pay.discountMoney,
                vouchers: orderInfo.pay.vouchers.map(voucher => ({
                    ...voucher,
                    product_id: voucher.productId,
                })),
                suppress_zero: orderInfo.pay.suppressZero,
            },
            products: orderInfo.products.map(product => ({
                ...product,
                singleDeposit: product.money,
            })),
        };
        delete send.userId;
        delete send.dispatchUid;
        delete send.deliveryTime;
        delete send.pay.discountMoney;
        delete send.bottleUseIds;
        delete send.pay.floorFee;
        delete send.pay.suppressZero;
        send.pay.vouchers.forEach(voucher => {
            delete voucher.productId;
        });
        return this.post(url, send);
    }

    // 保存会员报修单 （添加订单）
    saveRepairOrder(orderInfo) {
        const url = this.spellURL('saveRepairOrder', 'add_repair');
        const send = {
            ...this.transKeyName('underline', orderInfo),
        };
        return this.post(url, send);
    }
}

export const $fetchOrder = new FOrder();
