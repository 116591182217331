/**
 * CDateTimeRangePicker
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import './cDatePicker.scss';

export class CDateTimeRangePicker extends React.Component {
    static propTypes = {
        // 样式
        style: PropTypes.object,
        // 允许清空
        allowClear: PropTypes.bool,
        // 占位文字
        placeholder: PropTypes.arrayOf(PropTypes.string),
        // 是否显示秒钟
        showSecond: PropTypes.bool,
        // 值
        value: PropTypes.string,
        // 回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        allowClear: false,
        showSecond: false,
    };

    // 用户操作后设置输出
    _handleChange(dates) {
        if (!dates) {
            this.props.onChange('');
            return;
        }
        if (dates.length !== 2 || !dates[0] || !dates[1]) {
            // 当选择未完成时，不发送改变事件
            return;
        }
        const changedValues = [
            dates[0].format('YYYY-MM-DD HH:mm:ss'),
            dates[1].format('YYYY-MM-DD HH:mm:ss'),
        ];
        this.props.onChange(changedValues.join(','));
    }

    render() {
        const format = this.props.showSecond ? 'YYYY-M-D HH:mm:ss' : 'YYYY-M-D HH:mm';
        // 默认值转换
        const valArr = this.props.value ? this.props.value.split(',') : [null, null];
        valArr[0] = !valArr[0] || valArr[0] === '0' ? null : valArr[0];
        valArr[1] = !valArr[1] || valArr[1] === '0' ? null : valArr[1];
        const values = [
            valArr[0] && moment(valArr[0], format),
            valArr[1] && moment(valArr[1], format),
        ];
        return (
            <DatePicker.RangePicker
                className={[
                    'c-date-time-range-picker',
                    this.props.showSecond ? '' : 'no-second',
                ].join(' ')}
                style={this.props.style}
                showTime
                locale={locale}
                format={format}
                placeholder={this.props.placeholder || ['开始时间', '结束时间']}
                allowClear={this.props.allowClear}
                value={values}
                onChange={evt => this._handleChange(evt)}
            />
        );
    }
}
