/**
 * CHourRangePicker
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

export class CHourRangePicker extends React.Component {
    static propTypes = {
        // 样式
        style: PropTypes.object,
        // 允许清空
        allowClear: PropTypes.bool,
        // 显示分钟
        showMinute: PropTypes.bool,
        // 值
        value: PropTypes.string,
        // 回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        allowClear: false,
        showMinute: false,
    };

    // 用户操作后设置输出
    _handleChange(dates) {
        if (!dates) {
            this.props.onChange('');
            return;
        }
        if (dates.length !== 2 || !dates[0] || !dates[1]) {
            // 当选择未完成时，不发送改变事件
            return;
        }
        const format = this.props.showMinute ? 'HH:mm' : 'HH';
        const changedValues = [dates[0].format(format), dates[1].format(format)];
        this.props.onChange(changedValues.join(','));
    }

    render() {
        const format = this.props.showMinute ? 'HH:mm' : 'HH';
        const placeholder = this.props.showMinute ? '00:00' : '';
        // 默认值转换
        const valArr = this.props.value ? this.props.value.split(',') : [null, null];
        valArr[0] = !valArr[0] || valArr[0] === '0' ? null : valArr[0];
        valArr[1] = !valArr[1] || valArr[1] === '0' ? null : valArr[1];
        const values = [
            valArr[0] && moment(valArr[0], format),
            valArr[1] && moment(valArr[1], format),
        ];
        return (
            <TimePicker.RangePicker
                className={[
                    'c-hour-range-picker',
                    this.props.showMinute ? 'show-minute' : '',
                    this.props.className || '',
                ].join(' ')}
                dropdownClassName="c-hour-range-popup"
                style={this.props.style}
                format={format}
                placeholder={[placeholder, placeholder]}
                locale={locale}
                allowClear={this.props.allowClear}
                getPopupContainer={() => document.getElementById('dialogs')}
                value={values}
                onChange={evt => this._handleChange(evt)}
            />
        );
    }
}
