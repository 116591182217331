/**
 * CDraggation
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CDraggation extends React.Component {
    static propTypes = {
        onDragging: PropTypes.func,
    };

    static defaultProps = {
        onDragging: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.$refs = {};
        this._listeners = {};
    }

    componentDidMount() {
        let _x = 0,
            _y = 0,
            started = false;
        let lastTouch = null;
        this._listeners.onPointerStart = evt => {
            evt.deltaX = evt.deltaY = 0;
            // 手指
            if (evt.touches && evt.touches.length === 1) {
                lastTouch = evt.touches[0];
                _x = lastTouch.pageX;
                _y = lastTouch.pageY;
                started = true;
                this.props.onDragging(this._createDraggingEvent('dragStart', evt));
                CDraggation.dragging++;
            }
            // 鼠标
            else if (evt.buttons === 1) {
                _x = evt.pageX;
                _y = evt.pageY;
                started = true;
                this.props.onDragging(this._createDraggingEvent('dragStart', evt));
                CDraggation.dragging++;
            }
        };
        this._listeners.onPointerMove = evt => {
            if (!started) {
                return;
            }
            // 手指
            if (evt.touches && evt.touches.length === 1) {
                lastTouch = evt.touches[0];
                evt.deltaX = _x - lastTouch.pageX;
                evt.deltaY = _y - lastTouch.pageY;
                // 移动位置超过 3px，标记为已移动
                if (Math.abs(evt.deltaX) + Math.abs(evt.deltaY) > 3) {
                    evt.preventDefault();
                }
                this.props.onDragging(this._createDraggingEvent('dragMove', evt));
            }
            // 鼠标
            else if (evt.buttons === 1) {
                evt.deltaX = _x - evt.pageX;
                evt.deltaY = _y - evt.pageY;
                // 移动位置超过 3px，标记为已移动
                if (Math.abs(evt.deltaX) + Math.abs(evt.deltaY) > 3) {
                    evt.preventDefault();
                }
                this.props.onDragging(this._createDraggingEvent('dragMove', evt));
            }
        };
        this._listeners.onPointerEnd = evt => {
            if (!started) {
                return;
            }
            evt.preventDefault();
            evt.stopPropagation();
            evt.deltaX = _x - evt.pageX;
            evt.deltaY = _y - evt.pageY;
            this.props.onDragging(this._createDraggingEvent('dragEnd', evt, lastTouch));
            lastTouch = null;
            if (started) {
                _x = _y = 0;
                started = false;
                // 延迟设置操作中标记数
                setTimeout(() => {
                    CDraggation.dragging--;
                }, 350);
            }
        };
        document.addEventListener('mousemove', this._listeners.onPointerMove, true);
        document.addEventListener('touchmove', this._listeners.onPointerMove, {
            passive: false,
            capture: true,
        });
        document.addEventListener('mouseup', this._listeners.onPointerEnd, true);
        document.addEventListener('touchend', this._listeners.onPointerEnd, true);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this._listeners.onPointerMove);
        document.removeEventListener('touchmove', this._listeners.onPointerMove);
        document.removeEventListener('mouseup', this._listeners.onPointerEnd);
        document.removeEventListener('touchend', this._listeners.onPointerEnd);
    }

    // 创建拖拽事件
    _createDraggingEvent(dragType, evt, lastTouch) {
        let event;
        // 鼠标
        if (evt.type.indexOf('mouse') >= 0) {
            event = new MouseEvent('dragging', {
                bubbles: true,
                cancelable: true,
                clientX: evt.clientX,
                clientY: evt.clientY,
                ctrlKey: evt.ctrlKey,
                shiftKey: evt.shiftKey,
                altKey: evt.altKey,
            });
        }
        // 手指
        else if (evt.type.indexOf('touch') >= 0) {
            event = new TouchEvent('dragging', {
                bubbles: true,
                cancelable: true,
                ctrlKey: evt.ctrlKey,
                shiftKey: evt.shiftKey,
                altKey: evt.altKey,
            });
            const origTouch = evt.touches[0] || lastTouch;
            event.pageX = origTouch.pageX;
            event.pageY = origTouch.pageY;
            event.clientX = origTouch.clientX;
            event.clientY = origTouch.clientY;
        }
        event.deltaX = evt.deltaX;
        event.deltaY = evt.deltaY;
        event.draggingOrigType = evt.type;
        event.dragType = dragType;
        return event;
    }

    _onPointerStart(evt) {
        this._listeners.onPointerStart(evt.nativeEvent);
    }

    render() {
        return (
            <span
                onMouseDown={evt => this._onPointerStart(evt)}
                onTouchStart={evt => this._onPointerStart(evt)}
            >
                {this.props.children}
            </span>
        );
    }

    static dragging = 0;
}

// 屏蔽拖拽操作松手动作连带的点击事件
document.addEventListener(
    'click',
    evt => {
        if (CDraggation.dragging) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
    },
    true,
);
