/**
 * CInputRange
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './cInputRange.scss';

export class CInputRange extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 样式
        style: PropTypes.object,
        // 清空
        allowClear: PropTypes.bool,
        // 显示前缀
        prefix: PropTypes.string,
        // 显示后缀
        suffix: PropTypes.string,
        // 输入范围限制，例如：0,100
        limit: PropTypes.string,
        // 分隔符
        separator: PropTypes.string,
        // 输入框文本居中
        textAlign: PropTypes.oneOf(['left', 'center']),
        // 项值
        value: PropTypes.string,
        // 变化回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        separator: '~',
        textAlign: 'left',
    };

    constructor(props) {
        super(props);
        this.state = {
            min: this.props.value ? this.props.value.split(',')[0] || '' : '',
            max: this.props.value ? this.props.value.split(',')[1] || '' : '',
        };
        let limit = this.props.limit ? this.props.limit.split(',') : [];
        this._data = {
            min: limit[0] || -Infinity,
            max: limit[1] || Infinity,
        };
    }

    _triggerChange(value, type) {
        if (type === 'min') {
            if (!value && !this.state.max) {
                this.props.onChange('');
            } else {
                this.props.onChange(value + ',' + this.state.max);
            }
        } else if (type === 'max') {
            if (!value && !this.state.min) {
                this.props.onChange('');
            } else {
                this.props.onChange(this.state.min + ',' + value);
            }
        }
    }

    _handleChange(value, type) {
        let value2 = Number(value.replace(/\D/g, ''));
        if (type === 'min') {
            if (value2 > this._data.max) {
                value2 = this._data.max;
            }
        } else if (type === 'max') {
            if (value2 < this._data.min) {
                value2 = this._data.min;
            }
        }
        // 不相等修正
        if (value2 !== Number(value)) {
            this.setState({
                [type]: value2,
            });
            this._triggerChange(value2, type);
        }
        // 正常
        else {
            this.setState({
                [type]: value,
            });
            this._triggerChange(value, type);
        }
    }

    render() {
        return (
            <div
                className={[
                    'c-input-range',
                    this.props.suffix || this.props.prefix ? 'c-input-range-suffix' : '',
                    '~-:'.indexOf(this.props.separator) >= 0
                        ? ''
                        : 'c-input-range-sep-big',
                    this.props.textAlign === 'center' ? 'c-input-range-align-center' : '',
                    this.props.className,
                ].join(' ')}
                style={this.props.style}
            >
                <Input
                    value={this.state.min}
                    prefix={this.props.prefix}
                    suffix={<span>{this.props.suffix}</span>}
                    allowClear={this.props.allowClear}
                    onChange={evt => this._handleChange(evt.target.value, 'min')}
                    onBlur={evt => this._handleChange(evt.target.value, 'min')}
                />
                <span className="c-input-range-sep">{this.props.separator}</span>
                <Input
                    value={this.state.max}
                    prefix={this.props.prefix}
                    suffix={<span>{this.props.suffix}</span>}
                    allowClear={this.props.allowClear}
                    onChange={evt => this._handleChange(evt.target.value, 'max')}
                    onBlur={evt => this._handleChange(evt.target.value, 'max')}
                />
            </div>
        );
    }
}
