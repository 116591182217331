/**
 * Controller
 * @author Tevin
 */

import React from 'react';
import { Tools } from '@components/common/Tools';
import { $busNet } from '@components/bases/BusNet';

export class Director extends React.Component {

    /**
     * @constructor
     * @param {String} props
     * @param {String} className
     */
    constructor(props, className) {
        super(props);
        this.$refs = {};
        this.__name = className;
        this.__mounted = false;
        this.__listeners = {};
        this.__computeds = {};
        this.__proxy = {
            $setRefs: (element, refName) => {
                if (!element || !refName) {
                    return;
                }
                this.$refs[refName] = element;
            },
        };
        Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach(name => {
            // 限制必须是 on 开头的方法才进行代理
            if (/^on[A-Z][a-zA-Z0-9]+$/.test(name)) {
                this.__proxy[name] = (...argus) => {
                    this[name](...argus);
                };
            }
        });
    }

    /**
     * 附带事件网络通知的 setState
     * @param {Object|Function} newState
     */
    $setState(newState) {
        if (!newState) {
            return;
        }
        if (!this.__mounted) {
            return;
        }
        // 函数模式
        if (Tools.isFunction(newState)) {
            let newState2 = null;
            this.setState((state, props) => {
                newState2 = newState(state, props);
                setTimeout(() => {
                    for (let key in newState2) {
                        if (newState2.hasOwnProperty(key)) {
                            $busNet.trigger(this.__name + '@' + key, newState2[key]);
                        }
                    }
                }, 0);
                return newState2;
            });
        }
        // 普通对象模式
        else {
            if (Tools.isEmptyObject(newState)) {
                return;
            }
            this.setState(newState);
            setTimeout(() => {
                for (let key in newState) {
                    if (newState.hasOwnProperty(key)) {
                        $busNet.trigger(this.__name + '@' + key, newState[key]);
                    }
                }
            }, 0);
        }
    }

    /**
     * 发送事件
     * @param {String} type - 事件类型，语法为
     *      'BusName:eventName'
     * @param {*} [eventData]
     * @param {Function} [callback]
     * @example
     *      $trigger('Home:queryData', {test:1}, data=>{})
     */
    $trigger(type, eventData, callback) {
        $busNet.trigger(type, eventData, callback);
    }

    /**
     * 绑定事件网络，接受数据
     * @param {{computed:String}} computedStates - 接收跨模块计算属性，语法为
     *      {computedStateName: 'ClassName@stateName'} 版块内计算属性，可以省略版块名
     *      {computedStateName: 'BusName:ClassName@stateName'} 指定版块计算属性
     * @param {{listener:Function}} eventListeners - 接收跨模块事件，语法为
     *      {'eventName': listener} 版块内子模块间通讯，可以省略版块名
     *      {'BusName:eventName': listener} 指定版块间通讯
     *      {'$:eventName': listener}  匹配所有版块
     * @example
     *      $landBusNet({
     *          info: 'Home:DHome@accountInfo'
     *      }, {
     *          'Home:queryData': (evt, cb) => {}
     *      });
     */
    $landBusNet(computedStates, eventListeners) {
        this.__mounted = true;
        // 注册事件监听
        if (eventListeners && !Tools.isEmptyObject(eventListeners)) {
            this.__listeners = { ...eventListeners };
            $busNet.register(this.__listeners);
        }
        // 注册计算属性
        if (computedStates && !Tools.isEmptyObject(computedStates)) {
            for (let key in computedStates) {
                if (computedStates.hasOwnProperty(key)) {
                    // 跳过 on 开头的属性
                    if (/^on[A-z]/.test(key)) {
                        continue;
                    }
                    // 不能覆盖绑定 ref 的方法
                    if (key === '$setRefs') {
                        continue;
                    }
                    this.__computeds[key] = computedStates[key];
                    $busNet.on(this.__computeds[key], (value) => {
                        this.setState({
                            [key]: value,
                        });
                    });
                }
            }
        }
        // 发送初始属性
        setTimeout(() => {
            this.$setState(this.state);
            this.$trigger('Home:onPageOpened', $busNet.getName());
        }, 0);
    }

    /**
     * 移除事件网络绑定
     */
    $logoutBusNet() {
        this.__mounted = false;
        $busNet.unregister(this.__listeners);
        for (let key in this.__computeds) {
            if (this.__computeds.hasOwnProperty(key)) {
                $busNet.off(this.__computeds[key]);
            }
        }
    }

    /**
     * 检查事件网络是否在线
     * @param {String} busName - 事件网络名称
     * @param {Function} tillOnlineCB - 直到上线的回调，轮询检查直到上线为止，不传参不轮询
     * @return {Boolean} - 返回当前即刻的在线状态
     */
    $checkBusNet(busName, tillOnlineCB) {
        if (!busName) {
            return false;
        }
        if (tillOnlineCB) {
            const checkOnline = () => {
                // 当事件网络已经在线
                if ($busNet.checkBusNet(busName)) {
                    setTimeout(() => {
                        tillOnlineCB();
                    }, 0);
                }
                // 当事件网络仍不在线，延迟 100 毫秒，递归检查
                else {
                    setTimeout(() => {
                        checkOnline();
                    }, 100);
                }
            };
            checkOnline();
        }
        return $busNet.checkBusNet(busName);
    }

    /**
     * 渲染
     * @return {ReactNode}
     */
    render() {
        return (
            <>
                {React.Children.map(this.props.children, child => {
                    return React.cloneElement(child, {
                        ...this.props,
                        ...this.state,
                        ...this.__proxy,
                    });
                })}
            </>
        );
    }

}

export const wrapper = (Director) => (Surface) => class Wrapper extends React.Component {

    /**
     * 渲染
     * @return {ReactNode}
     */
    render() {
        return (
            <Director {...this.props}>
                <Surface/>
            </Director>
        );
    }

};



