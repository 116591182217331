/**
 * CDetailTabsModel
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs } from 'antd';
import {} from '@ant-design/icons';
import { CFitHeightModal } from '@components/plugins/fitHeightModal/CFitHeightModal';
import { CPlaceholderLink } from '@components/fragments/placeholderLink/CPlaceholderLink';
import './cDetailTabsModel.scss';

export class CDetailTabsModel extends React.Component {
    static propTypes = {
        // 占位文本
        placeholder: PropTypes.string,
        // 占位样式类型
        placeholderType: PropTypes.oneOf(['link', 'button', 'primary']),
        // 弹窗标题
        title: PropTypes.string,
        // 弹窗标签页栏位及内容列表
        tabs: PropTypes.array,
        // 默认打开哪页
        defaultTabKey: PropTypes.string,
        // 是否禁用
        disabled: PropTypes.bool,
        // 打开回调
        onOpenChange: PropTypes.func,
        // 标签页切换回调
        onTabsChange: PropTypes.func,
    };

    static defaultProps = {
        placeholderType: 'link',
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            width: Math.min(1200, window.innerWidth - 16),
        };
        this._listeners = {};
    }

    componentDidMount() {
        this._listeners.onResize = () => {
            this.setState({
                width: Math.min(1200, window.innerWidth - 16),
            });
        };
        window.addEventListener('resize', this._listeners.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._listeners.onResize);
    }

    _handleOpen() {
        this.setState({
            visible: true,
        });
        this.props.onOpenChange(true);
    }

    _handleClose() {
        this.setState({
            visible: false,
        });
        this.props.onOpenChange(false);
    }

    _renderContent() {
        const contentHeight = Math.min(650, window.innerHeight - 95);
        return (
            <div className="c-detail-content" style={{ minHeight: contentHeight }}>
                <Tabs
                    type="card"
                    defaultActiveKey={this.props.defaultTabKey}
                    onChange={evt => this.props.onTabsChange(evt)}
                >
                    {this.props.tabs &&
                        this.props.tabs.map((tab, index) => {
                            return (
                                <Tabs.TabPane tab={tab.name} key={tab.key || index}>
                                    {tab.render
                                        ? tab.render()
                                        : '-- tab ' + (index + 1) + ' --'}
                                </Tabs.TabPane>
                            );
                        })}
                </Tabs>
            </div>
        );
    }

    _renderPlaceholder() {
        switch (this.props.placeholderType) {
            // 链接样式
            case 'link':
                return (
                    <CPlaceholderLink
                        className={[
                            'c-detail-tabs-placeholder',
                            this.props.className,
                        ].join(' ')}
                        disabled={this.props.disabled}
                        text={this.props.placeholder}
                        onClick={evt => this._handleOpen()}
                    />
                );
            // 按钮样式
            case 'button':
            case 'primary':
            case 'danger':
                return (
                    <Button
                        className="c-detail-tabs-placeholder"
                        type={this.props.placeholderType}
                        size="small"
                        disabled={this.props.disabled}
                        onClick={evt => this._handleOpen()}
                    >
                        {this.props.placeholder}
                    </Button>
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <>
                {this._renderPlaceholder()}
                <CFitHeightModal
                    className="c-detail-tabs-model"
                    title={this.props.title}
                    width={this.state.width}
                    visible={this.state.visible}
                    onCancel={evt => this._handleClose()}
                >
                    {this._renderContent()}
                </CFitHeightModal>
            </>
        );
    }
}
