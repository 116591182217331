/**
 * Fetcher Truck
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FTruck extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/truck/', '/Home/Truck/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取车辆列表（车辆列表）
    getTruckList(search, pagination, sorter) {
        const url = this.spellURL('getTruckList', 'truck_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取车辆列表
    getTruckListAsSelect() {
        const url = this.spellURL('getTruckList', 'truck_list');
        const send = {
            page: 1,
            limit: 500,
            gettype: 'all',
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.carId,
                    name: item.plateNo,
                }));
            });
    }

    // 保存车辆信息（车辆列表）
    saveTruckInfo(truck) {
        const url = this.spellURL('saveTruckInfo', 'api_truck');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(truck)),
        };
        return this.post(url, send);
    }

    // 保存删除车辆（车辆列表）
    saveDeleteTruck(truck) {
        const url = this.spellURL('saveDeleteTruck', 'api_truck');
        const send = {
            ...this.transKeyName('underline', truck),
            'op_type': 'del',
        };
        return this.post(url, send);
    }

    // 保存司机绑定车辆（车队人员）
    saveBindTruck(truck) {
        const url = this.spellURL('saveBindTruck', '../worker/update_car');
        const send = {
            ...this.transKeyName('underline', truck),
        };
        return this.post(url, send);
    }

    // 获取车队配送报表（配送报表）
    getTransportReport(search, pagination, sorter) {
        const url = this.spellURL('getTransportReport', '../Area/storeTransport');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取车辆轨迹（车辆轨迹）
    getTruckTack() {
        const url = this.spellURL('getTruckTack', 'truck_track');
        const send = {
            type: 1,
        };
        return this.post(url, send);
    }

    // 获取等级会员列表（路线规划）
    getRouteUsersMap(search) {
        const url = this.spellURL('getRouteUsersMap', 'getUserList');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

}

export const $fetchTruck = new FTruck();

