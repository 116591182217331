/**
 * CRichEditor
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import {} from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import './cRichEditor.scss';

export class CRichEditor extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 初始项值
        value: PropTypes.any,
        // 改变项值后的回调
        onChange: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            editorState: null,
        };
        this._data = {
            atLivinig: false,
        };
    }

    componentDidMount() {
        this._data.atLivinig = true;
        if (this.props.value) {
            this.$initValue(this.props.value);
        }
    }

    componentWillUnmount() {
        this._data.atLivinig = false;
    }

    _handleChange(editorState) {
        this.setState({
            editorState: editorState,
        });
        // 还原换行空格策略
        const html = editorState
            .toHTML()
            .replace(/<p[^>]*?><\/p>/g, '<p><br/></p>')
            .replace(/ {2}/g, '&nbsp; ');
        this.props.onChange(html);
    }

    _createControls() {
        return [
            'undo',
            'redo',
            'separator',
            'bold',
            'italic',
            'underline',
            'text-color',
            'separator',
            'text-indent',
            'text-align',
            'separator',
            'list-ul',
            'list-ol',
            'blockquote',
            'hr',
            'separator',
            { key: 'clear', text: <b>清空</b> },
        ];
    }

    render() {
        return (
            <span className={['c-rich-editor', this.props.className].join(' ')}>
                <BraftEditor
                    value={this.state.editorState}
                    onChange={evt => this._handleChange(evt)}
                    placeholder={this.props.placeholder}
                    controls={this._createControls()}
                />
            </span>
        );
    }

    $initValue(content = '<p></p>') {
        if (!this._data.atLivinig) {
            return;
        }
        if (content !== '<p></p>') {
            // 替换换行策略
            content = content
                .replace(/<p[^>]*?><\/p>/g, '')
                .replace(/<p[^>]*?><br(\/)?><\/p>/g, '<p></p>');
        }
        // 需要转换
        if (content.indexOf('&lt;') >= 0) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = content;
            content = tempDiv.innerText || tempDiv.textContent;
        }
        this.setState({
            editorState: BraftEditor.createEditorState(content),
        });
    }
}
