/**
 * CEchartsPie - 饼图
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
// 图表类型
// import 'echarts/lib/chart/line';  // 折线图/面积图
// import 'echarts/lib/chart/bar';  // 柱状图/条形图
import 'echarts/lib/chart/pie'; // 饼图
// import 'echarts/lib/chart/sunburst';  // 旭日图
// import 'echarts/lib/chart/scatter';  // 散点（气泡）图
// import 'echarts/lib/chart/effectScatter';  // 涟漪特效动画的散点（气泡）图
// import 'echarts/lib/chart/radar';  // 雷达图
// import 'echarts/lib/chart/map';  // 地图
// import 'echarts/lib/chart/tree';  // 树图
// import 'echarts/lib/chart/treemap';  // 树状数据图
// import 'echarts/lib/chart/graph';  // 关系图
// import 'echarts/lib/chart/gauge';  // 仪表盘
// import 'echarts/lib/chart/funnel';  // 漏斗图
// import 'echarts/lib/chart/parallel';  // 平行坐标系图
// import 'echarts/lib/chart/sankey';  // 桑基图
// import 'echarts/lib/chart/boxplot';  // 箱形图/盒须图
// import 'echarts/lib/chart/candlestick';  // K线图
// import 'echarts/lib/chart/lines';  // 路径图
// import 'echarts/lib/chart/heatmap';  // 热力图
// import 'echarts/lib/chart/themeRiver';  // 主题河流图
// import 'echarts/lib/chart/pictorialBar';  // 象形柱图
// import 'echarts/lib/chart/custom';  // 自定义图型
// 图表组件
// import 'echarts/lib/component/graphic';  // 原生图形元素
// import 'echarts/lib/component/grid';  // 直角坐标系
import 'echarts/lib/component/legend'; // 图例
import 'echarts/lib/component/tooltip'; // 提示框
// import 'echarts/lib/component/polar';  // 极坐标系
// import 'echarts/lib/component/geo';  // 地理坐标系
// import 'echarts/lib/component/parallel';  // 平行坐标系
// import 'echarts/lib/component/singleAxis';  // 单轴
// import 'echarts/lib/component/brush';  // 区域选择
import 'echarts/lib/component/title'; // 标题
// import 'echarts/lib/component/dataZoom';  // 区域缩放
// import 'echarts/lib/component/visualMap';  // 视觉映射
// import 'echarts/lib/component/markPoint';  // 图表标注
// import 'echarts/lib/component/markLine';  // 图表标线
// import 'echarts/lib/component/markArea';  // 图表标域
// import 'echarts/lib/component/timeline';  // 时间线
// import 'echarts/lib/component/toolbox';  // 工具栏
// 图表引擎
// import 'zrender/lib/svg/svg';  // svg 渲染引擎
// import 'zrender/lib/vml/vml';  // vml 渲染引擎（类似 Canvas、SVG 的二维渲染引擎，IE Only）

import './cEchartsPie.scss';

export class CEchartsPie extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        loading: PropTypes.bool,
        option: PropTypes.object,
        height: PropTypes.number,
        style: PropTypes.object,
        showLoading: PropTypes.bool,
    };

    static defaultProps = {
        height: 300,
    };

    render() {
        return (
            <ReactEchartsCore
                className="c-echart-pie"
                echarts={echarts}
                option={this.props.option}
                style={{ height: this.props.height, ...this.props.style }}
                loadingOption={{ color: '#1890ff' }}
                showLoading={this.props.showLoading}
            />
        );
    }
}
