/**
 * DirectorOperationListing
 * @author Tevin
 */

import { Director } from '@components/bases/Director';

export class DirectorOperationListing extends Director {

    /**
     * @constructor
     * @param {String} props
     * @param {String} className
     */
    constructor(props, className) {
        super(props, className);
        // 标记搜索是否已改变，改变搜索需要重置页码到第一页
        this.$searchChanged = {
            dft: false,
        };
    }

    /**
     * 合并单个表格参数
     * @param {Object} tableParam
     * @return {{pagination: {}, sorter: {}}}
     */
    $combineTableParam(tableParam) {
        let pagination = null,
            sorter = null;
        // 修改表格参数更新
        if (tableParam) {
            pagination = {
                ...this.state.pagination,
                current: tableParam?.pagination?.current || this.state.pagination.current,
                pageSize: tableParam?.pagination?.pageSize || this.state.pagination.pageSize,
            };
            sorter = {
                field: tableParam?.sorter?.order ? tableParam.sorter.field : '',
                order: tableParam?.sorter?.order || '',
            };
        }
        // 修改搜索条件更新（不改变表格参数）
        else {
            pagination = this.state.pagination;
            sorter = this.state.sorter;
        }
        // 修改搜索条件后，页码还原到第一页
        if (this.$searchChanged.dft) {
            this.$setState({
                ...this.state.pagination,
                current: 1,
            });
            this.$searchChanged.dft = false;
            pagination.current = 1;
        }

        return { pagination, sorter };
    }

    /**
     * 在多个表格中，合并指名表格参数
     * @param {Object} tableParam
     * @param {String} [tableName]
     * @return {{pagination: {}, sorter: {}}}
     */
    $combineTableParamByName(tableParam, tableName) {
        let pagination = null,
            sorter = null;
        if (tableName === 'dft') {
            return {};
        }
        // 修改表格参数更新
        if (tableParam) {
            pagination = {
                ...this.state[tableName].pagination,
                current: tableParam?.pagination?.current || this.state[tableName].pagination.current,
                pageSize: tableParam?.pagination?.pageSize || this.state[tableName].pagination.pageSize,
            };
            sorter = {
                field: tableParam?.sorter?.order ? tableParam.sorter.field : '',
                order: tableParam?.sorter?.order || '',
            };
        }
        // 修改搜索条件更新（不改变表格参数）
        else {
            pagination = this.state[tableName].pagination;
            sorter = this.state[tableName].sorter;
        }
        // 修改搜索条件后，页码还原到第一页
        if (this.$searchChanged[tableName]) {
            this.$setState({
                [tableName]: {
                    ...this.state[tableName],
                    pagination: {
                        ...this.state[tableName].pagination,
                        current: 1,
                    },
                },
            });
            this.$searchChanged[tableName] = false;
            pagination.current = 1;
        }
        return { pagination, sorter };
    }


}
