/**
 * CGridItem
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

export class CGridItem extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
        // grid 布局参数
        layout: PropTypes.object,
        // 标题
        title: PropTypes.node,
        // 子标题（右边）
        sub: PropTypes.node,
        // 取消默认显示样式
        noDefaultStyle: PropTypes.bool,
        // 内容
        children: PropTypes.node,
    };

    static defaultProps = {};

    _renderSub() {
        if (this.props.sub) {
            return <span className="c-grid-box-sub">{this.props.sub}</span>;
        } else {
            return null;
        }
    }

    _renderTitle() {
        if (this.props.title) {
            return (
                <div className="c-grid-box-top">
                    <span className="c-grid-box-title">{this.props.title}</span>
                    {this._renderSub()}
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const {
            className,
            layout,
            title,
            sub,
            noDefaultStyle,
            children,
            ...resetProps
        } = this.props;
        const layout2 = {
            ...layout,
            xs: layout?.xs || 24,
        };
        return (
            <Col
                className={[
                    'c-grid-col',
                    className,
                    noDefaultStyle ? 'no-style' : '',
                ].join(' ')}
                {...layout2}
                {...resetProps}
            >
                <div className="c-grid-box-box">
                    {this._renderTitle()}
                    <div className="c-grid-box-content">{children}</div>
                </div>
            </Col>
        );
    }
}
