/**
 * CGeolocationPoint
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { EnvironmentOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { CFitHeightModal } from '@components/plugins/fitHeightModal/CFitHeightModal';
import './geolocationPoint.scss';

export class CGeolocationPoint extends React.Component {
    static propTypes = {
        // 可清除
        allowClear: PropTypes.bool,
        // 占位显示
        placeholder: PropTypes.string,
        // 默认搜索
        search: PropTypes.string,
        // 值
        value: PropTypes.string,
        // 回调
        onChange: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            point: '',
            search: '',
            showMap: false,
            showSearch: false,
            updateKeySh: 0,
        };
        this.$refs = {
            iframe: null,
            searchResult: null,
        };
        this._data = {
            map: null,
            BMap: null,
            localSearcher: null,
            searchPanel: document.createElement('div'),
        };
    }

    _parsePointToString(point) {
        const lngs = (point.lng + '').split('.'),
            lats = (point.lat + '').split('.');
        return (
            lngs[0] +
            '.' +
            lngs[1].substr(0, 6) +
            ',' +
            lats[0] +
            '.' +
            lats[1].substr(0, 6)
        );
    }

    _initMap() {
        const BMap = this._data.BMap;
        this.$refs.iframe.contentWindow.document.body.className = 'default-map';
        if (this.props.value) {
            const paramArr = this.props.value.split(',');
            const initPoint = new BMap.Point(
                parseFloat(paramArr[0]),
                parseFloat(paramArr[1])
            );
            this._data.map.addOverlay(new BMap.Marker(initPoint));
        }
        this._data.map.addEventListener('click', evt => {
            this.setState({
                point: this._parsePointToString(evt.point),
            });
            this._data.map.clearOverlays();
            this._data.map.addOverlay(new BMap.Marker(evt.point));
        });
        this._initSearch();
    }

    // 搜索
    _initSearch() {
        const BMap = this._data.BMap;
        let curResults = [];
        this._data.localSearcher = new BMap.LocalSearch(this._data.map, {
            renderOptions: {
                map: this._data.map,
                panel: this._data.searchPanel,
            },
            onSearchComplete: results => {
                // 判断状态是否正确
                if (
                    this._data.localSearcher.getStatus() ===
                    this.$refs.iframe.contentWindow.BMAP_STATUS_SUCCESS
                ) {
                    curResults = [];
                    for (var i = 0; i < results.getCurrentNumPois(); i++) {
                        curResults.push(results.getPoi(i));
                    }
                    // 搜索完成会自动选取第一个，保持同步
                    this.setState({
                        point: this._parsePointToString(curResults[0].point),
                    });
                }
            },
            onResultsHtmlSet: container => {
                container.querySelectorAll('ol > li').forEach((li, index) => {
                    li.addEventListener('click', () => {
                        this.setState({
                            point: this._parsePointToString(curResults[index].point),
                        });
                    });
                });
            },
        });
    }

    _handleOpen() {
        this.setState({
            point: this.props.value,
            // 每次重新打开从 props 更新搜索
            search: this.props.search,
            // 刷新搜索显示
            updateKeySh: Date.now(),
        });
    }

    _handleClose() {
        this.setState({
            showMap: false,
            showSearch: false,
        });
        this._data.searchPanel.innerHTML = '';
        this._data.map = this._data.BMap = this._data.localSearcher = null;
    }

    _handleToggleSearch(type) {
        if (!this._data.localSearcher) {
            return;
        }
        this.setState({
            showSearch: type === 'show',
        });
    }

    _handleGoSearch(evt) {
        this.setState({
            search: evt,
        });
        if (this._data.localSearcher) {
            this._data.localSearcher.search(evt);
        }
    }

    _triggerChange = changedValue => {
        this.props.onChange && this.props.onChange(changedValue);
        this.setState({
            value: changedValue,
            showMap: false,
        });
    };

    _renderSearch() {
        return (
            <div className="search">
                <Button type="primary" onClick={evt => this._handleToggleSearch('show')}>
                    搜索
                </Button>
                <div className="search-layer">
                    <div
                        className={[
                            'search-panel',
                            this.state.showSearch ? '' : 'off',
                        ].join(' ')}
                    >
                        <div className="search-close">
                            <DoubleRightOutlined />
                        </div>
                        <div className="search-top">
                            <Input.Search
                                placeholder="请输入地点搜索"
                                enterButton
                                defaultValue={this.state.search}
                                key={this.state.updateKeySh}
                                onSearch={evt => this._handleGoSearch(evt)}
                            />
                        </div>
                        <div
                            className="search-result"
                            ref={elm => {
                                // 更新
                                if (this.$refs.searchResult) {
                                    this.$refs.searchResult.removeChild(
                                        this._data.searchPanel
                                    );
                                }
                                this.$refs.searchResult = elm;
                                if (this.$refs.searchResult) {
                                    this.$refs.searchResult.appendChild(
                                        this._data.searchPanel
                                    );
                                }
                            }}
                        ></div>
                    </div>
                    <div
                        className={[
                            'search-mask',
                            this.state.showSearch ? '' : 'off',
                        ].join(' ')}
                        onClick={evt => this._handleToggleSearch('hide')}
                    ></div>
                </div>
            </div>
        );
    }

    _renderCurrent() {
        return (
            <div className="current">
                当前选择：{this.state.point ? <b>{this.state.point}</b> : '-,-'}
                <Button
                    type="primary"
                    disabled={!this.state.point}
                    onClick={evt => this._triggerChange(this.state.point)}
                >
                    确定
                </Button>
            </div>
        );
    }

    _renderIFrame() {
        if (this.state.showMap) {
            const src =
                '/static/assets/baiduMap/foundation.html?control=parent&point=' +
                this.props.value +
                '&build=' +
                window.buildDate;
            return (
                <iframe
                    title="geolocationPoint"
                    src={src}
                    ref={elm => (this.$refs.iframe = elm)}
                    onLoad={evt =>
                        this.$refs.iframe.contentWindow.initBadiuMap((map, BMap) => {
                            this._data.map = map;
                            this._data.BMap = BMap;
                            this._initMap();
                        })
                    }
                ></iframe>
            );
        } else {
            return <span></span>;
        }
    }

    render() {
        return (
            <>
                <Input
                    className="c-geolocation-point-input"
                    value={this.state.value}
                    addonAfter={
                        <span onClick={evt => this.setState({ showMap: true })}>
                            <EnvironmentOutlined />
                        </span>
                    }
                    placeholder={this.props.placeholder}
                    allowClear={this.props.allowClear}
                    onChange={evt => this._triggerChange(evt.target.value)}
                />
                <CFitHeightModal
                    className="c-geolocation-point"
                    visible={this.state.showMap}
                    title={this.props.placeholder || '请选择地理位置'}
                    onCancel={evt => this._handleClose()}
                    onOpened={evt => this._handleOpen()}
                >
                    {this._renderCurrent()}
                    {this._renderSearch()}
                    {this._renderIFrame()}
                </CFitHeightModal>
            </>
        );
    }
}
